import { ImageProcessor } from "@/image/processor";
import { catchError, from, map, Observable, Subscriber, switchMap } from "rxjs";
import loadImage from "blueimp-load-image";
import { injectable } from "inversify";
import axios from "axios";
import logger from "@/logger";
import { ImageManager, ObjectURL, UploadGcsImage } from "@/domain/interactor/image-manager";
import imageCompression from "browser-image-compression";
import { v4 as uuid } from "uuid";

@injectable()
export default class ImageProcessorImpl implements ImageManager {
  public resize(file: File, type?: "png" | "jpeg"): Observable<UploadGcsImage> {
    return from(
      (async () => {
        const maxFileSizeMB = 5;
        const targetFileSizeMB = 2;

        let processedFile = file;
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        const fileType = type ?? (fileExtension === "png" ? "image/png" : "image/jpeg");

        if (file.size > maxFileSizeMB * 1024 * 1024) {
          const options = {
            maxSizeMB: targetFileSizeMB,
            maxWidthOrHeight: 1024,
            useWebWorker: true,
            fileType: fileType.toLowerCase(),
          };

          try {
            processedFile = await imageCompression(file, options);
          } catch (err) {
            console.error("파일 압축 중 오류 발생:", err);
            throw new Error("파일 압축 중 문제가 발생했습니다.");
          }
        }

        // // **파일 이름을 UUID로 변경**
        // const newFileName = `${uuid()}.${fileExtension}`;
        // processedFile = new File([processedFile], newFileName, { type: processedFile.type });

        return processedFile;
      })(),
    ).pipe(
      switchMap((processedFile) =>
        // 압축된 파일 또는 원본 파일을 기존 sharp API에 전달
        axios.post(`/api/sharp?filename=${processedFile.name}&type=${type ?? "jpeg"}`, processedFile),
      ),
      map((res) => {
        return res.data;
      }),
      catchError((err) => {
        alert(`이미지 업로드를 실패하였습니다. 다시 시도해주세요.`);
        logger.error("public", "resize", { location: "ImageProcessorImpl" }, { err });
        throw err;
      }),
    );
  }

  //기존 sharp 로직
  // public resize(file: File, type?: "png" | "jpeg"): Observable<UploadGcsImage> {
  //   return from(axios.post(`/api/sharp?filename=${file.name}&type=${type ?? "jpeg"}`, file)).pipe(
  //     map((res) => {
  //       return res.data;
  //     }),
  //     catchError((err) => {
  //       alert("이미지 업로드를 실패하였습니다. 다시 시도해주세요.");
  //       logger.error("public", "resize", { location: "ImageProcessorImpl" }, { err });
  //       throw err;
  //     }),
  //   );
  // }

  createObjectURL(url: string): Observable<ObjectURL> {
    return new Observable<{ originURL: string; objectURL: string }>((observable: Subscriber<{ originURL: string; objectURL: string }>) => {
      async function imageUrlToBlob(url: string) {
        const response = await fetch(url);
        const blob = await response.blob();
        return blob;
      }

      imageUrlToBlob(url).then((blob) => {
        const objURL = URL.createObjectURL(blob);

        observable.next({ originURL: url, objectURL: objURL });
      });
    });
  }

  createURLToFile(url: string): Observable<File> {
    return new Observable<File>((observable: Subscriber<File>) => {
      async function imageUrlToBlob(url: string) {
        const response = await fetch(url);
        const blob = await response.blob();
        return blob;
      }
      imageUrlToBlob(url).then((blob) => {
        const urlSplit = url.split("/").pop();
        const filename = urlSplit?.split("?")[0];

        const metadata = { type: `image/png` };
        const file = new File([blob], encodeURI(filename ?? "unknown"), metadata);
        observable.next(file);
      });
    });
  }

  sortOrientation(file: File): Observable<File> {
    return new Observable<File>((observable: Subscriber<File>) => {
      // file.arrayBuffer().then((arrayBuffer) => {
      //   const blob = new Blob([new Uint8Array(arrayBuffer)], {
      //     type: file.type
      //   });
      //   observable.next(blob);
      // });
      loadImage(file, {
        meta: true,
        orientation: true,
        canvas: true,
      }).then((image) => {
        (image.image as any).toBlob((blob: any) => {
          const rotateFile = new File([blob], file.name, file);

          observable.next(rotateFile);
        }, file.type);
      });
      // observable.next(file);
    });
  }

  limitSize(file: File, size: number, byteUnit?: "KB" | "MB" | "GB" | "TB"): Observable<File> {
    return new Observable<File>((observable: Subscriber<File>) => {
      let sizeLimit: number = size;

      if (byteUnit === "KB") sizeLimit = sizeLimit * 1024;
      if (byteUnit === "MB") sizeLimit = sizeLimit * 1024 * 1024;
      if (byteUnit === "GB") sizeLimit = sizeLimit * 1024 * 1024 * 1024;
      if (byteUnit === "TB") sizeLimit = sizeLimit * 1024 * 1024 * 1024 * 1024;

      if (file.size <= sizeLimit) observable.next(file);
      else {
        observable.error({
          fileSizeErrorLimit: `${size}${byteUnit ? byteUnit : "Byte"}`,
          fileSize: file.size,
        });
      }
    });
  }
}
