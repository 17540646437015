import Icon from "@icon/index";
import { Button, Layout, Typo } from "@view/widget";
import { NextRouter, useRouter } from "next/router";
import React, { useState } from "react";
import styled from "styled-components";
import { MenuItem } from "./MenuItem";

interface LeftMenuItemProps {
  expanded: boolean;
  pathname: string;
  menuName: string;
  clickMenu: () => void;
}

const LeftMenuItem: React.FC<LeftMenuItemProps> = ({ expanded, pathname, menuName, clickMenu }) => {
  const router: NextRouter = useRouter();
  const isActiveTab = (path: string) => router.asPath.includes(path);

  const [isHovered, setIsHovered] = useState(false);

  const subMenuContent = (pathname: string, name: string) => {
    switch (pathname) {
      case "customers":
        if (name === "상담고객 관리") {
          return (
            <ItemWrapper
              active={isActiveTab("/face-fit/customers")}
              expanded={expanded}
            >
              <IconWrapper>
                <Icon.MenuFaceFitCustomers
                  small
                  color={
                    isActiveTab("/face-fit/customers") ? "drwo_prim_green_800" : expanded ? "drwo_prim_disabled_600" : "drwo_prim_green_200"
                  }
                />
              </IconWrapper>

              {expanded ? (
                <TextWrapper>
                  <Typo.B2
                    color={isActiveTab("/face-fit/customers") ? "#033632" : "#8D9797"}
                    value={name}
                  />
                </TextWrapper>
              ) : null}
            </ItemWrapper>
          );
        } else if (name === "상담신청 관리") {
          return (
            <ItemWrapper
              active={isActiveTab("/advertisement/customers")}
              expanded={expanded}
            >
              <Icon.MenuAdCustomers
                small
                color={
                  isActiveTab("/advertisement/customers")
                    ? "drwo_prim_green_800"
                    : expanded
                    ? "drwo_prim_disabled_600"
                    : "drwo_prim_green_200"
                }
              />

              {expanded ? (
                <TextWrapper>
                  <Typo.B2
                    color={isActiveTab("/advertisement/customers") ? "#033632" : "#8D9797"}
                    value={name}
                  />
                </TextWrapper>
              ) : null}
            </ItemWrapper>
          );
        }

      case "best-practice":
        return (
          <MenuItem
            name={name}
            active={isActiveTab("/face-fit/best-practice")}
            expanded={expanded}
            icon={
              <Icon.FaceOnOff
                small
                color={
                  isActiveTab("/face-fit/best-practice")
                    ? "drwo_prim_green_800"
                    : expanded
                    ? "drwo_prim_disabled_600"
                    : "drwo_prim_green_200"
                }
              />
            }
          />
        );

      case "face-styles":
        return (
          <MenuItem
            name={name}
            active={isActiveTab("/face-fit/face-styles")}
            expanded={expanded}
            icon={
              <Icon.OutlinedStyleFit
                small
                color={
                  isActiveTab("/face-fit/face-styles") ? "drwo_prim_green_800" : expanded ? "drwo_prim_disabled_600" : "drwo_prim_green_200"
                }
              />
            }
          />
        );

      case "information":
        return (
          <ItemWrapper
            active={isActiveTab("/hospital/information")}
            expanded={expanded}
          >
            <Icon.MenuInformation
              small
              color={
                isActiveTab("/hospital/information") ? "drwo_prim_green_800" : expanded ? "drwo_prim_disabled_600" : "drwo_prim_green_200"
              }
            />
            {expanded ? (
              <TextWrapper>
                <Typo.B2
                  color={isActiveTab("/hospital/information") ? "#033632" : "#8D9797"}
                  value={name}
                />
              </TextWrapper>
            ) : null}
          </ItemWrapper>
        );

      case "doctors":
        return (
          <ItemWrapper
            active={isActiveTab("/hospital/doctors")}
            expanded={expanded}
          >
            <Icon.MenuDoctors
              small
              color={isActiveTab("/hospital/doctors") ? "drwo_prim_green_800" : expanded ? "drwo_prim_disabled_600" : "drwo_prim_green_200"}
            />
            {expanded ? (
              <TextWrapper>
                <Typo.B2
                  color={isActiveTab("/hospital/doctors") ? "#033632" : "#8D9797"}
                  value={name}
                />
              </TextWrapper>
            ) : null}
          </ItemWrapper>
        );
      case "contacts":
        return (
          <ItemWrapper
            active={isActiveTab("/hospital/contacts")}
            expanded={expanded}
          >
            <Icon.MenuContacts
              small
              color={
                isActiveTab("/hospital/contacts") ? "drwo_prim_green_800" : expanded ? "drwo_prim_disabled_600" : "drwo_prim_green_200"
              }
            />
            {expanded ? (
              <TextWrapper>
                <Typo.B2
                  color={isActiveTab("/hospital/contacts") ? "#033632" : "#8D9797"}
                  value={name}
                />
              </TextWrapper>
            ) : null}
          </ItemWrapper>
        );
      case "accounts":
        return (
          <ItemWrapper
            active={isActiveTab("/hospital/accounts")}
            expanded={expanded}
          >
            <Icon.MenuAccount
              small
              color={
                isActiveTab("/hospital/accounts") ? "drwo_prim_green_800" : expanded ? "drwo_prim_disabled_600" : "drwo_prim_green_200"
              }
            />
            {expanded ? (
              <TextWrapper>
                <Typo.B2
                  color={isActiveTab("/hospital/accounts") ? "#033632" : "#8D9797"}
                  value={name}
                />
              </TextWrapper>
            ) : null}
          </ItemWrapper>
        );
      case "plan":
        return (
          <ItemWrapper
            active={isActiveTab("/hospital/plan")}
            expanded={expanded}
          >
            <Icon.MenuPlan
              small
              color={isActiveTab("/hospital/plan") ? "drwo_prim_green_800" : expanded ? "drwo_prim_disabled_600" : "drwo_prim_green_200"}
            />
            {expanded ? (
              <TextWrapper>
                <Typo.B2
                  color={isActiveTab("/hospital/plan") ? "#033632" : "#8D9797"}
                  value={name}
                />
              </TextWrapper>
            ) : null}
          </ItemWrapper>
        );

      case "analytics":
        return (
          <ItemWrapper
            active={isActiveTab("/hospital/analytics")}
            expanded={expanded}
          >
            <Icon.FilledChart
              small
              color={isActiveTab("/hospital/analytics") ? "drwo_prim_green_800" : expanded ? "drwo_prim_disabled_600" : "drwo_prim_green_200"}
            />
            {expanded ? (
              <TextWrapper>
                <Typo.B2
                  color={isActiveTab("/hospital/analytics") ? "#033632" : "#8D9797"}
                  value={name}
                />
              </TextWrapper>
            ) : null}
          </ItemWrapper>
        );
    }
  };

  return (
    <Layout.Stack
      sx={{ padding: "0 12px" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={clickMenu}
    >
      <Layout.Box sx={{ position: "relative" }}>
        <Button.ComponentButton
          component={<>{subMenuContent(pathname, menuName)}</>}
          onClick={clickMenu}
        />
        {isHovered && !expanded ? (
          <Tooltip>
            <TooltipText>{menuName}</TooltipText>
          </Tooltip>
        ) : null}
      </Layout.Box>
    </Layout.Stack>
  );
};

export default LeftMenuItem;

const ItemWrapper = styled.div<{ active: boolean; expanded: boolean }>`
  width: 100%;
  padding: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0 6px;

  border-radius: ${(props) => (props.active ? "8px" : "0px")};
  background-color: ${(props) => (props.active ? (props.expanded ? "#E7EBDA" : "#ffffff") : "transparent")};
  &:hover {
    background-color: ${(props) => (props.expanded ? "#E7EBDA" : "#36766D")};
    border-radius: 8px;
    color: #033632;
  }
`;

const TextWrapper = styled.div`
  min-width: 93px;
  overflow-x: hidden;
  text-align: left;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Tooltip = styled.div`
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(4.5px, -50%);
  background-color: #141e20;
  color: #c9e6e2;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 10;
`;

const TooltipText = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  text-align: left;
`;
