import React from "react";
import Snackbar from "@mui/material/Snackbar";
import styled from "styled-components";
import Icon from "@icon/index";
import Button from "@view/widget/button";

interface ToastProps {
  open: boolean;
  message: string;
  position?: {
    vertical: "bottom" | "top";
    horizontal: "left" | "center" | "right";
  };
  type: "SUCCESS" | "WARNING" | "ERROR";
  noAutoHide?: boolean;
  close: () => void;
}

const Toast: React.FC<ToastProps> = ({ open, message, position, type, noAutoHide, close }) => {
  return (
    <ToastLayout>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: position?.vertical ?? "bottom", horizontal: position?.horizontal ?? "center" }}
        key={"bottom" + "center"}
        autoHideDuration={noAutoHide ? undefined : 2000}
        onClose={close}
        sx={position?.vertical === "top" ? { top: "124px !important" } : {}}
      >
        <ContentLayout $type={type}>
          <MessageLayout>
            {type === "ERROR" ? (
              <Icon.CloseCircleFill
                colorCode="#fff"
                _18px
              />
            ) : (
              <Icon.FilledCircleCheck
                colorCode="#fff"
                _16px
              />
            )}
            <Message>{message}</Message>
          </MessageLayout>
          <Button.ComponentButton
            component={
              <Icon.Close
                colorCode="#fff"
                _16px
              />
            }
            borderRadius={"50%"}
            onClick={close}
          />
        </ContentLayout>
      </Snackbar>
    </ToastLayout>
  );
};
export default Toast;

const ToastLayout = styled.div``;

const ContentLayout = styled.div<{ $type: "SUCCESS" | "WARNING" | "ERROR" }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 370px;
  height: 40px;
  background-color: ${({ $type }) => {
    if ($type === "SUCCESS") return "#04cd3b";
    if ($type === "WARNING") return "#333";
    if ($type === "ERROR") return "#FF3D13";
  }};
  box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding-inline: 24px;
`;

const Message = styled.div`
  color: #fff;
  font-weight: 500;
`;

const MessageLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
