import { TYPES } from "@/data/interactor/repository/index.container.type";
import type { AnalyticsRepository } from "@/domain/interactor/repository/AnalyticsRepository";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import {
  GetConsultingAnalyticsGroupByDate,
  GetConsultingAnalyticsGroupByDateInput,
  GetConsultingAnalyticsGroupByDateOutput,
} from "./model/GetConsultingAnalyticsGroupByDate";

@injectable()
export class GetConsultingAnalyticsGroupByDateImpl implements GetConsultingAnalyticsGroupByDate {
  constructor(@inject(TYPES.AnalyticsRepository) private readonly analyticsRepository: AnalyticsRepository) {}

  execute(input: GetConsultingAnalyticsGroupByDateInput): Observable<GetConsultingAnalyticsGroupByDateOutput> {
    return this.analyticsRepository.getOperationConsultingAnalyticsGroupByDate(input.start, input.end);
  }
}
