import { injectable, inject } from "inversify";
import { Observable } from "rxjs";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";
import {
  EmbeddingExtractionBestPractice,
  EmbeddingExtractionBestPracticeInput,
  EmbeddingExtractionBestPracticeOutput,
} from "./model/EmbeddingExtractionBestPractice";

@injectable()
export class EmbeddingExtractionBestPracticeImpl implements EmbeddingExtractionBestPractice {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: EmbeddingExtractionBestPracticeInput): Observable<EmbeddingExtractionBestPracticeOutput> {
    return this.operationConsultingRepository.embeddingExtractionBestPractice(input.bestPracticeId);
  }
}
