import { injectable } from "inversify";
import { BehaviorSubject, mergeMap, of, Subject, zip } from "rxjs";
import {
  AnalyticsPerformanceSearchOption,
  PerformanceAnalyticGroupedByConsultantData,
  ReservationLineChartData,
  ReservationLineFilterOption,
} from "@view-data/Analytics";
import type { UISystemManager } from "../ui-system/view-model/model";
import type {
  GetConsultingAnalyticsGroupByConsultant,
  GetOperationConsultingAnalyticsGroupByDateItem,
  GetConsultingAnalyticsGroupByConsultantOutput,
  ConsultingData,
} from "@/domain/usecase/analytics/model/GetConsultingAnalyticsGroupByConsultant";
import { PerformanceAnalyticsViewModel } from "./model/PerformanceAnalyticsViewModel";
import type { GetConsultingAnalyticsFirstCreatedDate } from "@/domain/usecase/analytics/model/GetConsultingAnalyticsFirstCreatedDate";

@injectable()
export class PerformanceAnalyticsViewModelImpl implements PerformanceAnalyticsViewModel {
  data: PerformanceAnalyticsViewModel["data"] = {
    searchOptions: [],
    weeklyGroupedData: [],
    monthlyGroupedData: [],
  };
  output: PerformanceAnalyticsViewModel["output"] = {
    searchOptions: new Subject<AnalyticsPerformanceSearchOption[]>(),
    selectedSearchOption: new Subject<AnalyticsPerformanceSearchOption>(),
    reservationLineChartData: new Subject<ReservationLineChartData[]>(),
    reservationLineFilterOptions: new Subject<ReservationLineFilterOption[]>(),
  };

  constructor(
    readonly uiSystem: UISystemManager,
    private readonly ucGetConsultingAnalyticsGroupByConsultant: GetConsultingAnalyticsGroupByConsultant,
    private readonly ucGetConsultingAnalyticsFirstCreatedDate: GetConsultingAnalyticsFirstCreatedDate,
  ) {
    this.init();
  }

  input: PerformanceAnalyticsViewModel["input"] = {
    selectSearchOption: (option: AnalyticsPerformanceSearchOption) => {
      if (option.month) {
        const filteredData = this.data.weeklyGroupedData.filter((item) => item.month === option.month && item.year === option.year);
        this.output.reservationLineChartData.next(filteredData);
        this.output.reservationLineFilterOptions.next(this.createFilterOptions(filteredData));
        this.output.selectedSearchOption.next(option);
      } else {
        const filteredData = this.data.monthlyGroupedData.filter((item) => item.year === option.year);
        this.output.reservationLineChartData.next(filteredData);
        this.output.reservationLineFilterOptions.next(this.createFilterOptions(filteredData));
        this.output.selectedSearchOption.next(option);
      }
    },
  };

  private init = () => {
    const sub = this.ucGetConsultingAnalyticsFirstCreatedDate
      .execute()
      .pipe(
        mergeMap((output) => {
          if (output.startAt) {
            this.data.weeklyGroupedData = this.getMonthlyWeeks(this.getMondayOfWeek(output.startAt));
            this.data.monthlyGroupedData = this.getMonths(output.startAt);
          }

          return zip(
            this.data.weeklyGroupedData.map((weekData) =>
              zip(
                this.ucGetConsultingAnalyticsGroupByConsultant.execute({
                  start: this.formatDateStringToYYYYMMDD(weekData.startDate),
                  end: this.formatDateStringToYYYYMMDD(weekData.endDate),
                }),
                of(weekData),
              ),
            ),
          );
        }),
        mergeMap(([...output]) => {
          this.data.weeklyGroupedData = output.map(([analyticsConsultantData, weekData]) => {
            const { items } = analyticsConsultantData;
            return this.summarizeData(weekData, items);
          });
          this.data.searchOptions = [...this.data.searchOptions, ...this.generateMonthlySearchOptions(this.data.weeklyGroupedData)];
          this.output.searchOptions.next(this.data.searchOptions);
          //   this.output.reservationLineFilterOptions.next(this.createFilterOptions(this.data.weeklyGroupedData));
          //   this.output.reservationLineChartData.next(this.data.weeklyGroupedData);
          return this.data.monthlyGroupedData.length > 0
            ? zip(
                this.data.monthlyGroupedData.map((monthData) =>
                  zip(
                    this.ucGetConsultingAnalyticsGroupByConsultant.execute({
                      start: this.formatDateStringToYYYYMMDD(monthData.startDate),
                      end: this.formatDateStringToYYYYMMDD(monthData.endDate),
                    }),
                    of(monthData),
                  ),
                ),
              )
            : of();
        }),
      )
      .subscribe({
        next: ([...output]) => {
          this.data.monthlyGroupedData = output.map(([analyticsConsultantData, monthData]) => {
            const { items } = analyticsConsultantData;
            return this.summarizeData(monthData, items);
          });
          this.data.searchOptions = [
            ...this.generateYearlySearchOptions(this.data.monthlyGroupedData),
            ...this.generateMonthlySearchOptions(this.data.weeklyGroupedData),
          ];
          this.output.searchOptions.next(this.data.searchOptions);
          if (this.data.searchOptions.length > 0) {
            this.input.selectSearchOption(this.data.searchOptions[this.data.searchOptions.length - 1]);
          }
          //   this.output.reservationLineFilterOptions.next(this.createFilterOptions(this.data.weeklyGroupedData));
          //   this.output.reservationLineChartData.next(this.data.weeklyGroupedData);
        },
      });
  };

  private summarizeData = (
    baseChartData: ReservationLineChartData,
    groupedByConsultantDataItems: GetOperationConsultingAnalyticsGroupByDateItem[],
  ): ReservationLineChartData => {
    const summary: ReservationLineChartData = {
      ...baseChartData,
    };

    groupedByConsultantDataItems.forEach((item) => {
      const consultCount = item.consulted.length;
      const reservationCount = item.reserved.length;
      const completedCount = item.completed.length;
      //   const reservationRatio = consultCount !== 0 ? 100 : Math.round((reservationCount / consultCount) * 100);
      const reservationRatio = consultCount === 0 && reservationCount >= 0 ? 100 : Math.round((reservationCount / consultCount) * 100);

      summary[item.consultantId] = {
        name: item.consultantName,
        consultCount,
        reservationCount,
        reservationRatio,
        completedCount,
      };
    });

    return summary;
  };

  private formatDateStringToYYYYMMDD = (date: Date): string => {
    // 입력값이 유효한지 검사

    // Date 객체가 유효하지 않으면 빈 문자열 반환
    if (isNaN(date.getTime())) {
      return "";
    }

    // 년, 월, 일 추출
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 1을 더해줌
    const day = String(date.getDate()).padStart(2, "0"); // 날짜가 한 자릿수일 경우 0을 채워줌

    // YYYYMMDD 형식으로 반환
    return `${year}${month}${day}`;
  };

  private getMondayOfWeek = (date: Date): Date => {
    const day = date.getDay(); // 일요일은 0, 월요일은 1, ...
    const diff = (day + 6) % 7; // 월요일을 0으로 맞추기 위해서
    const monday = new Date(date);
    monday.setDate(date.getDate() - diff); // 월요일로 날짜 조정
    return monday;
  };

  private getMonths = (contractedAt: Date): Pick<ReservationLineChartData, "year" | "month" | "startDate" | "endDate" | "week">[] => {
    const result: Pick<ReservationLineChartData, "year" | "month" | "startDate" | "endDate" | "week">[] = [];

    const currentDate = new Date(); // 오늘 날짜
    let start = new Date(contractedAt); // 계약 시작일
    start.setDate(1); // 계약 시작월의 1일로 설정

    while (start <= currentDate) {
      const year = start.getFullYear();
      const month = start.getMonth() + 1; // JS에서는 월이 0부터 시작하므로 +1
      const startDate = new Date(year, month - 1, 1); // 해당 월의 시작일
      const endDate = new Date(year, month, 0); // 해당 월의 마지막 날

      result.push({
        year,
        month,
        week: null, // week는 null로 설정
        startDate,
        endDate,
      });

      // 다음 달로 이동
      start.setMonth(start.getMonth() + 1);
    }

    return result;
  };

  private getMonthlyWeeks = (contractedAt: Date): Pick<ReservationLineChartData, "year" | "month" | "endDate" | "startDate" | "week">[] => {
    const currentDate = new Date(); // 현재 날짜
    const result: { year: number; month: number; week: number; startDate: Date; endDate: Date }[] = [];

    let year = contractedAt.getFullYear();
    let month = contractedAt.getMonth(); // 0-based month (0 = January, 11 = December)

    // 계약일부터 현재 날짜까지 반복
    while (year < currentDate.getFullYear() || (year === currentDate.getFullYear() && month <= currentDate.getMonth())) {
      const startOfMonth = new Date(year, month, 1); // 월 시작일
      const endOfMonth = new Date(year, month + 1, 0); // 월 마지막 날

      // 해당 월의 첫 번째 월요일을 기준으로 1주차 시작
      let firstMondayOfMonth = this.getMondayOfWeek(startOfMonth);
      if (firstMondayOfMonth.getMonth() !== month) {
        // 첫 번째 월요일이 이전 달에 포함되면, 두 번째 월요일로 이동
        firstMondayOfMonth.setDate(firstMondayOfMonth.getDate() + 7);
      }

      let currentWeekStart = firstMondayOfMonth; // 주 시작일
      let weekNumber = 1; // 주차 번호 시작

      // 계약일이 포함되는 첫 주를 찾기
      let startCountingWeeks = false;

      while (currentWeekStart <= endOfMonth && currentWeekStart <= currentDate) {
        const currentWeekEnd = new Date(currentWeekStart);
        currentWeekEnd.setDate(currentWeekStart.getDate() + 6); // 주의 끝일 계산

        // 계약일이 포함된 첫 번째 주부터 주차 데이터를 포함
        if (contractedAt <= currentWeekEnd && !startCountingWeeks) {
          startCountingWeeks = true; // 계약일이 포함된 첫 주부터 주차 카운트 시작
        }

        if (startCountingWeeks) {
          result.push({
            year,
            month: month + 1, // 1-based month (1-12)
            week: weekNumber,
            startDate: new Date(currentWeekStart),
            endDate: new Date(currentWeekEnd),
          });
        }

        // 다음 주로 이동
        currentWeekStart.setDate(currentWeekStart.getDate() + 7); // 다음 주의 시작일
        weekNumber++; // 주차 번호 증가
      }

      // 다음 월로 이동
      month++;
      if (month === 12) {
        month = 0;
        year++;
      }
    }

    return result;
  };


  // ISO 8601 표준 주차 기준 주차데이터 생성 로직
  //   private getMonthlyWeeks = (
  //     contractedAt: Date
  //   ): Pick<ReservationLineChartData, "year" | "month" | "endDate" | "startDate" | "week">[] => {
  //     const currentDate = new Date(); // 현재 날짜
  //     const result: { year: number; month: number; week: number; startDate: Date; endDate: Date }[] = [];

  //     let year = contractedAt.getFullYear();
  //     let month = contractedAt.getMonth(); // 0-based month (0 = January, 11 = December)

  //     // 계약일부터 현재 날짜까지 반복
  //     while (year < currentDate.getFullYear() || (year === currentDate.getFullYear() && month <= currentDate.getMonth())) {
  //       const startOfMonth = new Date(year, month, 1); // 월 시작일
  //       const endOfMonth = new Date(year, month + 1, 0); // 월 마지막 날

  //       let currentWeekStart = this.getMondayOfWeek(startOfMonth); // 월요일 기준 주 시작일
  //       let weekNumber = 1; // 주차 번호 시작

  //       while (currentWeekStart <= endOfMonth && currentWeekStart <= currentDate) {
  //         const currentWeekEnd = new Date(currentWeekStart);
  //         currentWeekEnd.setDate(currentWeekStart.getDate() + 6); // 주의 끝일 계산

  //         // 목요일 계산
  //         const currentWeekThursday = new Date(currentWeekStart);
  //         currentWeekThursday.setDate(currentWeekStart.getDate() + 3);

  //         // 목요일이 해당 월에 포함되는지 확인
  //         if (currentWeekThursday.getMonth() === month) {
  //           // 계약일 이후 주차만 추가
  //           if (currentWeekStart >= contractedAt) {
  //             result.push({
  //               year,
  //               month: month + 1, // 1-based month (1-12)
  //               week: weekNumber,
  //               startDate: new Date(currentWeekStart),
  //               endDate: new Date(currentWeekEnd),
  //             });
  //           }

  //           weekNumber++; // 주차 번호 증가
  //         }

  //         // 다음 주로 이동
  //         currentWeekStart.setDate(currentWeekStart.getDate() + 7); // 다음 주의 시작일
  //       }

  //       // 다음 월로 이동
  //       month++;
  //       if (month === 12) {
  //         month = 0;
  //         year++;
  //       }
  //     }

  //     return result;
  //   };

  private createFilterOptions = (chartDataArray: ReservationLineChartData[]): ReservationLineFilterOption[] => {
    const filterOptionsMap: Map<number, ReservationLineFilterOption> = new Map();

    chartDataArray.forEach((chartData) => {
      // Object.entries로 컨설턴트 데이터를 순회
      Object.entries(chartData).forEach(([key, value]) => {
        const consultantId = parseInt(key, 10);

        // year, month, week 등의 숫자 속성을 무시
        if (isNaN(consultantId)) return;

        const consultantData = value as {
          name: string;
          consultCount: number;
          reservationCount: number;
          reservationRatio: number;
          completedCount: number;
        };

        // Map에 consultantId가 없으면 추가
        if (!filterOptionsMap.has(consultantId)) {
          filterOptionsMap.set(consultantId, {
            consultantId,
            name: consultantData.name,
            active: true, // 기본 active 상태
          });
        }
      });
    });

    // Map의 값을 배열로 변환하여 반환
    return Array.from(filterOptionsMap.values());
  };

  private generateMonthlySearchOptions = (data: ReservationLineChartData[]): AnalyticsPerformanceSearchOption[] => {
    // 월별 데이터를 집계
    const monthlyData: { [key: string]: { year: number; month: number; totalConsults: number; totalReservations: number } } = {};

    data.forEach((item) => {
      const { year, month } = item;

      // 월별 데이터 키 생성 (예: "2024-11")
      const key = `${year}-${month}`;

      if (!monthlyData[key]) {
        monthlyData[key] = { year, month, totalConsults: 0, totalReservations: 0 };
      }

      // 컨설턴트 데이터를 합산
      Object.keys(item).forEach((key) => {
        const consultantId = parseInt(key, 10);

        if (!isNaN(consultantId)) {
          const consultantData = item[consultantId];
          monthlyData[`${year}-${month}`].totalConsults += consultantData.consultCount;
          monthlyData[`${year}-${month}`].totalReservations += consultantData.reservationCount;
        }
      });
    });

    // 월별 데이터를 정렬하여 계산
    const sortedKeys = Object.keys(monthlyData).sort((a, b) => {
      const [yearA, monthA] = a.split("-").map(Number);
      const [yearB, monthB] = b.split("-").map(Number);
      return yearA === yearB ? monthA - monthB : yearA - yearB;
    });

    const result: AnalyticsPerformanceSearchOption[] = [];
    let previousReservedPercent: number | null = null;

    sortedKeys.forEach((key, index) => {
      const { year, month, totalConsults, totalReservations } = monthlyData[key];
      const reservedPercent = totalConsults > 0 ? Math.round((totalReservations / totalConsults) * 100) : 0;

      result.push({
        id: `monthly-${index + 1}`,
        year,
        month,
        reservedPercent,
        beforeReservedPercent: previousReservedPercent ? reservedPercent - previousReservedPercent : null,
      });

      previousReservedPercent = reservedPercent;
    });

    return result;
  };

  private generateYearlySearchOptions = (data: ReservationLineChartData[]): AnalyticsPerformanceSearchOption[] => {
    // 연별 데이터를 집계
    const yearlyData: { [key: string]: { year: number; totalConsults: number; totalReservations: number } } = {};

    data.forEach((item) => {
      const { year, month } = item;

      // 연도별 데이터 키 생성 (예: "2024")
      if (!yearlyData[year]) {
        yearlyData[year] = { year, totalConsults: 0, totalReservations: 0 };
      }

      // 컨설턴트 데이터를 합산
      Object.keys(item).forEach((key) => {
        const consultantId = parseInt(key, 10);

        if (!isNaN(consultantId)) {
          const consultantData = item[consultantId];
          yearlyData[year].totalConsults += consultantData.consultCount;
          yearlyData[year].totalReservations += consultantData.reservationCount;
        }
      });
    });

    // 연도별 데이터를 정렬하여 계산
    const sortedYears = Object.keys(yearlyData).sort((a, b) => Number(a) - Number(b));

    const result: AnalyticsPerformanceSearchOption[] = [];
    let previousReservedPercent: number | null = null;

    sortedYears.forEach((year, index) => {
      const { totalConsults, totalReservations } = yearlyData[year];
      const reservedPercent = totalConsults > 0 ? Math.round((totalReservations / totalConsults) * 100) : 0;

      result.push({
        id: `yearly-${index + 1}`,
        year: Number(year),
        month: null, // month는 null로 설정
        reservedPercent,
        beforeReservedPercent: previousReservedPercent ? reservedPercent - previousReservedPercent : null,
      });

      previousReservedPercent = reservedPercent;
    });

    return result;
  };
}
