import { Coordinate } from "..";
import { DrawingFacialLandmarks } from "../face/DrawingFacialLandmarks";
import { Config } from "../index.interface";
import Calc from "../utils/Calc";
import { BestRatioLineStyle, PhotoBaseLine } from "../utils/Styles";

//비주소엽각
export class ColumellarLobularAngle extends DrawingFacialLandmarks {
  private frontalFaceCanvasDrawer: DrawingFacialLandmarks;
  private coordinate: {
    top: {
      top: Coordinate;
      bottom: Coordinate;
    };
    bottom: {
      top: Coordinate;
      bottom: Coordinate;
    };
  };
  ratio: {
    angle: number;
  };

  constructor({ config, frontalFaceCanvas }: { config: Config; frontalFaceCanvas: HTMLCanvasElement }) {
    super(config);
    this.frontalFaceCanvasDrawer = new DrawingFacialLandmarks({
      imageEle: config.imageEle,
      canvasEle: frontalFaceCanvas,
      normalizedCoordinate: config.normalizedCoordinate,
    });
    this.coordinate = this.getCoordinate();
    this.ratio = this.calcRatio();
  }

  drawPhotoBase = async () => {
    this.photoBaseDrawer.drawNoseLines();
    this.photoBaseDrawer.drawFrontalFaceLines();
    this.delay(300);
    this.photoBaseDrawer.drawAngleText();
  };

  drawBestRatio = async () => {
    this.bestRatioDrawer.drawNoseLines();
    this.bestRatioDrawer.drawFrontalFaceLines();
    this.delay(300);
    this.bestRatioDrawer.drawAngleText();
  };

  private photoBaseDrawer = {
    drawNoseLines: () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.top.top,
        point2: this.coordinate.top.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.drawLineBetweenPoints({
        point1: this.coordinate.bottom.top,
        point2: this.coordinate.bottom.bottom,
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });
      this.drawArcBetweenPoints({
        center: this.coordinate.top.bottom,
        point1: this.coordinate.top.top,
        point2: this.coordinate.bottom.bottom,
        radius: 15,
        lineStyle: PhotoBaseLine,
      });
    },

    drawAngleText: () => {
      const textStyle = { x: 25, y: 30, fontSize: 18, color: "#FF9900" };
      this.drawTextBetweenPoints(`${this.ratio.angle}°`, this.coordinate.top.top, this.coordinate.top.bottom, textStyle);
    },

    drawFrontalFaceLines: () => {
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[4],
        point2: this.faceCoordinates[2],
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[2],
        point2: this.faceCoordinates[0],
        lineStyle: PhotoBaseLine,
        isDrawEndPoint: true,
      });

      this.frontalFaceCanvasDrawer.drawArcBetweenPoints({
        center: this.faceCoordinates[2],
        point1: this.faceCoordinates[4],
        point2: this.faceCoordinates[0],
        radius: 15,
        lineStyle: PhotoBaseLine,
      });
    },
  };

  private bestRatioDrawer = {
    drawNoseLines: () => {
      this.drawLineBetweenPoints({
        point1: this.coordinate.bottom.top,
        point2: this.coordinate.bottom.bottom,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.coordinate.top.bottom,
        point2: this.coordinate.top.top,
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.drawArcBetweenPoints({
        center: this.coordinate.top.bottom,
        point1: this.coordinate.top.top,
        point2: this.coordinate.bottom.bottom,
        radius: 15,
        lineStyle: BestRatioLineStyle,
      });
    },

    drawAngleText: () => {
      const textStyle = { x: 10, y: 57, fontSize: 18, color: "#00D7CA" };
      this.drawTextBetweenPoints(`${97}°`, this.coordinate.top.top, this.coordinate.top.bottom, textStyle);
    },

    drawFrontalFaceLines: () => {
      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[4],
        point2: this.faceCoordinates[2],
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.frontalFaceCanvasDrawer.drawLineBetweenPoints({
        point1: this.faceCoordinates[2],
        point2: this.faceCoordinates[0],
        lineStyle: BestRatioLineStyle,
        isDrawEndPoint: true,
      });

      this.frontalFaceCanvasDrawer.drawArcBetweenPoints({
        center: this.faceCoordinates[2],
        point1: this.faceCoordinates[4],
        point2: this.faceCoordinates[0],
        radius: 15,
        lineStyle: BestRatioLineStyle,
      });
    },
  };

  private getCoordinate = (): {
    top: {
      top: Coordinate;
      bottom: Coordinate;
    };
    bottom: {
      top: Coordinate;
      bottom: Coordinate;
    };
  } => {
    const coordinateByInclinationToLeft = Calc.getCoordinateByInclination({
      point1: this.sideFaceCoordinate[2],
      point2: this.sideFaceCoordinate[19],
      length: 40,
      direction: "RIGHT",
    });

    const coordinateByInclinationToRight = Calc.getCoordinateByInclination({
      point1: this.sideFaceCoordinate[2],
      point2: this.sideFaceCoordinate[0],
      length: 30,
      direction: "RIGHT",
    });

    return {
      top: {
        top: coordinateByInclinationToLeft,
        bottom: this.sideFaceCoordinate[2],
      },
      bottom: {
        top: this.sideFaceCoordinate[2],
        bottom: coordinateByInclinationToRight,
      },
    };
  };

  private calcRatio = () => {
    const angle = Calc.calcAngle(
      {
        point1: this.coordinate.top.top,
        center: this.coordinate.top.bottom,
        point2: this.coordinate.bottom.bottom,
      },
      "2d",
    );

    return {
      angle,
    };
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
