import { inject, injectable } from "inversify";
import { GetSignedURL, GetSignedURLInput, GetSignedURLOutput } from "@/domain/usecase/gcs/model/GetSignedURL";
import type { ImageManager } from "@/domain/interactor/image-manager";
import type { Storage } from "@/domain/interactor/storage";
import { Observable, throwError } from "rxjs";

@injectable()
export class GetSignedURLImpl implements GetSignedURL {
  constructor(@inject("ImageManager") private readonly imageManager: ImageManager, @inject("Storage") private readonly storage: Storage) {}

  execute = (input: GetSignedURLInput): Observable<GetSignedURLOutput> => {
    try {
      const bucket = this.getBucket(input.privateURL);
      return this.storage.getSignedURL(bucket.bucket, bucket.pathAndFilename, Date.now() + input.expiresIn);
    } catch (err) {
      return throwError(() => err);
    }
  };

  private getBucket = (url: string) => {
    if (url.startsWith("https://storage.googleapis.com/")) {
      const bucket = url.replace("https://storage.googleapis.com/", "").split("/")[0];
      const pathAndFilename = url.split(`${bucket}/`)[1];
      if (!bucket || !pathAndFilename) {
        throw "invalid url";
      }
      return {
        bucket,
        pathAndFilename,
      };
    }
    if (url.startsWith("https://storage.cloud.google.com/")) {
      const bucket = url.replace("https://storage.cloud.google.com/", "").split("/")[0];
      const pathAndFilename = url.split(`${bucket}/`)[1];
      if (!bucket || !pathAndFilename) {
        throw "invalid url";
      }
      return {
        bucket,
        pathAndFilename,
      };
    }
    throw "invalid url";
  };
}
