import { Observable } from "rxjs";
import { API } from "../../API";
import { injectable } from "inversify";
import * as Request from "./model/Request";
import * as Response from "./model/Response";
import { AnalyticsAPI } from "./model/AnalyticAPI";

@injectable()
export class AnalyticsAPIImpl implements AnalyticsAPI {
  getOperationConsultingAnalyticsGroupByDate = (
    request: Request.GetOperationConsultingAnalyticsGroupByDate,
  ): Observable<Response.GetOperationConsultingAnalyticsGroupByDate> => {
    return API.get({
      credential: true,
      path: `/dr-wo/v1/analytics/operation-consulting/group-by/date`,
      queryParams: {
        start: request.start,
        end: request.end,
      },
    });
  };

  getOperationConsultingAnalyticsGroupByConsultant = (
    request: Request.GetOperationConsultingAnalyticsGroupByConsultant,
  ): Observable<Response.GetOperationConsultingAnalyticsGroupByConsultant> => {
    return API.get({
      credential: true,
      path: `/dr-wo/v1/analytics/operation-consulting/group-by/consultant`,
      queryParams: {
        start: request.start,
        end: request.end,
      },
    });
  };

  getOperationConsultingStartAt = (): Observable<Response.getOperationConsultingStartAt> => {
    return API.get({
      credential: true,
      path: `/dr-wo/v1/analytics/operation-consulting/start-at`,
    });
  };
}
