import Calc from "../utils/Calc";
import { CanvasDrawer } from "../utils/CanvasDrawer";
import { Config, ConnectionPoint, DenormalizedCoordinate, CanvasLineStyle, Coordinate } from "../index.interface";
import { BestRatioLineStyle, PhotoBaseLine } from "../utils/Styles";

export class EyeTail extends CanvasDrawer {
  private readonly landmarkPoint: ConnectionPoint[] = [
    { start: 133, end: 130 },
    { start: 362, end: 359 },
    { start: 25, end: 225 },
    { start: 153, end: 381 },
    { start: 33, end: 263 },
  ];
  private denormalizedCoordinate: DenormalizedCoordinate;

  eyeTailAngle: { 왼눈: number; 우눈: number };

  constructor({ imageEle, canvasEle, normalizedCoordinate }: Config) {
    super(imageEle, canvasEle);
    this.denormalizedCoordinate = Calc.getDenormalizedFaceLandmark(imageEle, normalizedCoordinate, this.landmarkPoint);
    this.eyeTailAngle = this.calcAngle();
  }

  drawPhotoBase = () => {
    this.drawLineBetweenPoints({
      point1: this.denormalizedCoordinate[133],
      point2: this.denormalizedCoordinate[130],
      lineStyle: { ...PhotoBaseLine, lineType: "DOT" },
      isDrawEndPoint: true,
    });

    this.drawLineBetweenPoints({
      point1: this.denormalizedCoordinate[362],
      point2: this.denormalizedCoordinate[359],
      lineStyle: { ...PhotoBaseLine, lineType: "DOT" },
      isDrawEndPoint: true,
    });

    setTimeout(() => {
      this.drawTextBetweenPoints(`${this.eyeTailAngle.왼눈}°`, this.denormalizedCoordinate[133], this.denormalizedCoordinate[153], {
        x: -10,
        y: 20,
        fontSize: 18,
        color: "#FF9900",
      });

      this.drawTextBetweenPoints(`${this.eyeTailAngle.우눈}°`, this.denormalizedCoordinate[362], this.denormalizedCoordinate[381], {
        x: 20,
        y: 20,
        fontSize: 18,
        color: "#FF9900",
      });
    }, 600);
  };

  drawBestRatio = () => {
    const rightEyeTextStyle = { x: -50, y: 20, fontSize: 18, color: "#00D7CA" };
    const leftEyeTextStyle = { x: 58, y: 20, fontSize: 18, color: "#00D7CA" };
    const length = Calc.calculateDistance(this.denormalizedCoordinate[133], this.denormalizedCoordinate[33], "2d");
    const rightEyeCoordinate = Calc.calculateCoordinateByAngleAndDirection(this.denormalizedCoordinate[133], 6, length, "TOP_RIGHT");
    const leftEyeCoordinate = Calc.calculateCoordinateByAngleAndDirection(this.denormalizedCoordinate[362], 6, length, "TOP_LEFT");

    setTimeout(() => {
      this.drawLineBetweenPoints({
        point1: this.denormalizedCoordinate[133],
        point2: rightEyeCoordinate,
        lineStyle: { ...BestRatioLineStyle, lineType: "DOT" },
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: this.denormalizedCoordinate[362],
        point2: leftEyeCoordinate,
        lineStyle: { ...BestRatioLineStyle, lineType: "DOT" },
        isDrawEndPoint: true,
      });
    }, 900);

    setTimeout(() => {
      this.drawTextBetweenPoints(`6°`, this.denormalizedCoordinate[133], this.denormalizedCoordinate[153], rightEyeTextStyle);
      this.drawTextBetweenPoints(`6°`, this.denormalizedCoordinate[362], this.denormalizedCoordinate[381], leftEyeTextStyle);
    }, 1200);
  };

  private calcAngle = () => {
    const coordinate = this.denormalizedCoordinate;

    const leftAngle = Calc.calculateAngleWithVectors({
      pointA: coordinate[33],
      pointB: coordinate[133],
    });

    const rightAngle = Calc.calculateAngleWithVectors({
      pointA: coordinate[362],
      pointB: coordinate[263],
    });

    return {
      왼눈: Calc.truncateDecimals(leftAngle, 1),
      우눈: Calc.truncateDecimals(Math.abs(rightAngle), 1),
    };
  };
}
