import { injectable } from "inversify";
import { ImageViewModel } from "./model/ImageViewModel";
import type { GetSignedURL } from "@/domain/usecase/gcs/model/GetSignedURL";
import { BehaviorSubject, Observable } from "rxjs";

@injectable()
export class ImageViewModelImpl implements ImageViewModel {
  output = {
    signedURL: new BehaviorSubject<string | null>(null),
  };
  constructor(private readonly ucGetSignedURL: GetSignedURL) {}

  getSignedURL = (privateURL: string, expiresIn: number) => {
    const sub = this.ucGetSignedURL
      .execute({
        privateURL,
        expiresIn,
      })
      .subscribe({
        next: ({ signedUrl }) => {
          this.output.signedURL.next(signedUrl);
          sub.unsubscribe();
        },
        error: (err) => {
          console.log("체크", err);
        },
      });
  };
}
