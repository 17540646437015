import { Config } from "../index.interface";
import { DotLineStyle } from "../utils/Styles";
import { DrawingFacialLandmarks } from "./DrawingFacialLandmarks";

export class NoseGuideLine extends DrawingFacialLandmarks {
  private frontalFaceCanvasDrawer: DrawingFacialLandmarks;

  constructor({ config, guideLineCanvas }: { config: Config; guideLineCanvas: HTMLCanvasElement }) {
    super(config);
    this.frontalFaceCanvasDrawer = new DrawingFacialLandmarks({
      imageEle: config.imageEle,
      canvasEle: guideLineCanvas,
      normalizedCoordinate: config.normalizedCoordinate,
    });
  }

  drawGuideLine = async () => {
    const PointStyleBold = {
      color: "rgba(255, 255, 255, 0.60)",
    };
    const ratio = 0.42;
    const dotLineStyle = { ...DotLineStyle, lineColor: "rgba(255, 255, 255, 0.60)" };

    const keyPoints = [10, 151, 9, 168, 6, 197, 195, 4, 2, 14, 152];

    this.drawLineBetweenPoints({
      point1: this.sideFaceCoordinate[10],
      point2: { ...this.faceCoordinates[10], x: this.faceCoordinates[10].x - this.faceCoordinates[10].x * ratio },
      lineStyle: dotLineStyle,
    });

    this.drawLineBetweenPoints({
      point1: this.sideFaceCoordinate[9],
      point2: { ...this.faceCoordinates[9], x: this.faceCoordinates[9].x - this.faceCoordinates[9].x * ratio },
      lineStyle: dotLineStyle,
    });

    this.drawLineBetweenPoints({
      point1: { ...this.sideFaceCoordinate[168] },
      point2: { ...this.faceCoordinates[168], x: this.faceCoordinates[168].x - this.faceCoordinates[168].x * ratio },
      lineStyle: dotLineStyle,
    });

    this.drawLineBetweenPoints({
      point1: { ...this.sideFaceCoordinate[4], x: this.sideFaceCoordinate[4].x },
      point2: { ...this.faceCoordinates[4], x: this.faceCoordinates[4].x - this.faceCoordinates[4].x * ratio },
      lineStyle: dotLineStyle,
    });

    this.drawLineBetweenPoints({
      point1: this.sideFaceCoordinate[2],
      point2: { ...this.faceCoordinates[2], x: this.faceCoordinates[2].x - this.faceCoordinates[2].x * ratio },
      lineStyle: dotLineStyle,
    });

    this.drawLineBetweenPoints({
      point1: this.sideFaceCoordinate[152],
      point2: { ...this.faceCoordinates[152], x: this.faceCoordinates[152].x - this.faceCoordinates[152].x * ratio },
      lineStyle: dotLineStyle,
    });

    this.drawLineBetweenPoints({
      point1: this.sideFaceCoordinate[14],
      point2: { ...this.faceCoordinates[14], x: this.faceCoordinates[14].x - this.faceCoordinates[4].x * ratio },
      lineStyle: dotLineStyle,
    });

    await this.delay(1000);

    keyPoints.forEach((keyPoint) => {
      this.frontalFaceCanvasDrawer.drawPoint(this.faceCoordinates[keyPoint], PointStyleBold);
    });
  };

  private delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
}
