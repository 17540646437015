export const TYPES = {
  FeedbackAPI: Symbol.for("FeedbackAPI"),
  AuthAPI: Symbol.for("AuthAPI"),
  UserAPI: Symbol.for("UserAPI"),
  AdAPI: Symbol.for("AdAPI"),
  NoticeAPI: Symbol.for("NoticeAPI"),
  HospitalAPI: Symbol.for("HospitalAPI"),
  DoctorAPI: Symbol.for("DoctorAPI"),
  OperationConsultingAPI: Symbol.for("OperationConsultingAPI"),
  AnalyticsAPI: Symbol.for("AnalyticsAPI")
};
