import type { AnalyticsAPI } from "@/data/remote/api/analytics/model/AnalyticAPI";
import { TYPES } from "@/data/remote/api/index.container.type";
import { AnalyticsRepository } from "@/domain/interactor/repository/AnalyticsRepository";
import { DoctorRepository } from "@/domain/interactor/repository/DoctorRepository";
import * as VO from "@/domain/interactor/repository/vo";
import Utils from "./Utils";
import { inject, injectable } from "inversify";
import { map, Observable } from "rxjs";
import { ConsultingData } from "@/data/remote/api/analytics/model/Response";

@injectable()
export class AnalyticsRepositoryImpl implements AnalyticsRepository {
  constructor(@inject(TYPES.AnalyticsAPI) private readonly analyticsAPI: AnalyticsAPI) {}

  getOperationConsultingAnalyticsGroupByDate = (
    start: string,
    end: string,
  ): Observable<VO.Analytics.GetOperationConsultingAnalyticsGroupByDate> => {
    return this.analyticsAPI
      .getOperationConsultingAnalyticsGroupByDate({
        start,
        end,
      })
      .pipe(
        map((res) => ({
          items: res.items.map((item) => ({
            date: item.date,
            consulted: item.consulted.map(this.consultingDataMapper),
            reserved: item.reserved.map(this.consultingDataMapper),
            completed: item.completed.map(this.consultingDataMapper),
          })),
        })),
      );
  };

  getOperationConsultingAnalyticsGroupByConsultant = (
    start: string,
    end: string,
  ): Observable<VO.Analytics.GetOperationConsultingAnalyticsGroupByConsultant> => {
    return this.analyticsAPI
      .getOperationConsultingAnalyticsGroupByConsultant({
        start,
        end,
      })
      .pipe(
        map((res) => ({
          items: res.items.map((item) => ({
            consultantId: item.consultantId,
            consultantName: item.consultantName,
            consultantRole: item.consultantRole,
            consultantStatus: item.consultantStatus,
            consulted: item.consulted.map(this.consultingDataMapper),
            reserved: item.reserved.map(this.consultingDataMapper),
            completed: item.completed.map(this.consultingDataMapper),
          })),
        })),
      );
  };

  getOperationConsultingStartAt = (): Observable<VO.Analytics.StartAt> => {
    return this.analyticsAPI.getOperationConsultingStartAt().pipe(
      map((res) => ({
        startAt: res.startAt ? Utils.convertDateStringToLocalDate(res.startAt) : null,
      })),
    );
  };

  private consultingDataMapper = (dto: ConsultingData): VO.Analytics.ConsultingData => {
    return {
      id: dto.id,
      hospitalId: dto.hospitalId,
      operationConsultingNoteId: dto.operationConsultingNoteId,
      consultingResultIndex: dto.consultingResultIndex,
      consultantId: dto.consultantId,
      consultantName: dto.consultantName,
      consultantRole: dto.consultantRole,
      consultantStatus: dto.consultantStatus,
      customerReferral: dto.customerReferral,
      customerGender: dto.customerGender,
      consultingStatus: dto.consultingStatus,
      consultingDate: Utils.convertDateStringToLocalDate(dto.consultingDate),
      reservationStatus: dto.reservationStatus,
      reservationDate: Utils.convertDateStringToLocalDate(dto.reservationDate),
    };
  };
}
