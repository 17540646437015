import * as ViewData from "@/application/view-data";
import type { GetBestPracticePhotos } from "@/domain/usecase/consulting/model/GetBestPracticePhotos";
import { injectable } from "inversify";
import { Subject } from "rxjs";
import { FaceBestPracticesSliderViewModel } from "./model/FaceBestPracticesSliderViewModel";
@injectable()
export class FaceBestPracticesSliderViewModelImpl implements FaceBestPracticesSliderViewModel {
  data: FaceBestPracticesSliderViewModel["data"] = {
    bestPractices: null,
    selectedBestPractice: null,
  };
  output: FaceBestPracticesSliderViewModel["output"] = {
    bestPractices: new Subject<ViewData.FaceFit.BestPractice[]>(),
    selectedBestPractice: new Subject<ViewData.FaceFit.BestPractice>(),
  };

  constructor(bestPractices: ViewData.Consulting.BeforeAndAfterReview[], private readonly getBestPracticePhotos: GetBestPracticePhotos) {
    this.init(bestPractices);
  }

  event: FaceBestPracticesSliderViewModel["event"] = {
    onInitSlide: (selectedId, bestPractices) => {
      this.init(bestPractices);
      if (this.data.bestPractices) {
        const selectedBestPractice = this.data.bestPractices.find((bestPractice) => {
          return bestPractice.id === selectedId;
        });

        if (selectedBestPractice) {
          this.output.selectedBestPractice.next(selectedBestPractice);
        }
      }
    },
  };

  private init = (bestPractices: ViewData.Consulting.BeforeAndAfterReview[]) => {
    this.data.bestPractices = bestPractices.map((bestPractice) => {
      const beforeAndAfterItems: ViewData.FaceFit.BeforeAndAfter[] = [];

      bestPractice.before.photos?.forEach((photo) => {
        beforeAndAfterItems.push({
          before: {
            id: photo.id,
            ordinal: photo.ordinal,
            originURL: photo.originUrl,
            objURL: null,
            type: "BEFORE",
            kind: photo.kind,
          },
          after: null,
        });
      });

      bestPractice.after.photos?.forEach((photo) => {
        const index = beforeAndAfterItems.findIndex((item) => item.before?.ordinal === photo.ordinal);

        if (index > -1) {
          beforeAndAfterItems[index].after = {
            id: photo.id,
            ordinal: photo.ordinal,
            originURL: photo.originUrl,
            objURL: null,
            type: "AFTER",
            kind: photo.kind,
          };
        }
      });

      return {
        id: bestPractice.id,
        publicData: bestPractice.publicData,
        beforeAndAfterItems,
        label: bestPractice.after.label ?? null,
        operationCategories: bestPractice.operationCategories,
        ratio: bestPractice.ratio,
        elapsedTime: bestPractice.elapsedTime,
        tags: bestPractice.tags,
      };
    });

    this.output.bestPractices.next([...this.data.bestPractices]);
  };
}
