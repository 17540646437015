import Calc from "../utils/Calc";
import { CanvasDrawer } from "../utils/CanvasDrawer";
import { Config, ConnectionPoint, DenormalizedCoordinate, CanvasLineStyle, Coordinate, LandmarkPoint } from "../index.interface";
import { BestRatioLineStyle, DotLineStyle, PhotoBaseLine } from "../utils/Styles";

export class EyesAspectRatio extends CanvasDrawer {
  private landmarkPoints: LandmarkPoint[] = [130, 133, 159, 145, 362, 359, 386, 374, 386, 374];

  private denormalizedCoordinate: DenormalizedCoordinate;
  private eyesCoordinate: {
    leftEye: {
      leftTop: Coordinate;
      rightTop: Coordinate;
      rightBottom: Coordinate;
      leftBottom: Coordinate;
    };
    rightEye: {
      leftTop: Coordinate;
      rightTop: Coordinate;
      rightBottom: Coordinate;
      leftBottom: Coordinate;
    };
  };

  private photoBaseLineStyle: CanvasLineStyle = {
    lineLength: 100,
    lineWidth: 1.5,
    lineColor: "#FF9900",
    lineType: "SOLID",
  };

  ratio: {
    left: { 높이: number; 길이: number };
    right: { 높이: number; 길이: number };
  };

  constructor({ imageEle, canvasEle, normalizedCoordinate }: Config) {
    super(imageEle, canvasEle);
    this.denormalizedCoordinate = Calc.getDenormalizedCoordinates(imageEle, normalizedCoordinate, this.landmarkPoints);
    this.eyesCoordinate = this.setEyesCoordinate();
    const ratio = this.calcAspectRatio();
    this.ratio = {
      left: { 높이: ratio.left.height, 길이: ratio.left.length },
      right: {
        높이: ratio.right.height,
        길이: ratio.right.length,
      },
    };
  }

  drawPhotoBase = () => {
    const rightEyeTextStyle = { x: 0, y: -50, fontSize: 18, color: "#FF9900" };
    const leftEyeTextStyle = { x: 0, y: -50, fontSize: 18, color: "#FF9900" };

    this.drawLineBetweenPoints({
      point1: this.denormalizedCoordinate[130],
      point2: this.denormalizedCoordinate[133],
      lineStyle: PhotoBaseLine,
      isDrawEndPoint: true,
    });

    this.drawLineBetweenPoints({
      point1: this.denormalizedCoordinate[362],
      point2: this.denormalizedCoordinate[359],
      lineStyle: PhotoBaseLine,
      isDrawEndPoint: true,
    });

    setTimeout(() => {
      this.drawLineBetweenPoints({
        point1: { ...this.denormalizedCoordinate[159], y: this.denormalizedCoordinate[159].y - 4 },
        point2: this.denormalizedCoordinate[145],
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: { ...this.denormalizedCoordinate[386], y: this.denormalizedCoordinate[386].y - 4 },
        point2: this.denormalizedCoordinate[374],
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });
    }, 300);

    setTimeout(() => {
      this.drawTextBetweenPoints(
        `${this.ratio.right.높이} : ${this.ratio.right.길이}`,
        this.denormalizedCoordinate[130],
        this.denormalizedCoordinate[133],
        rightEyeTextStyle,
      );
      this.drawTextBetweenPoints(
        `${this.ratio.left.높이} : ${this.ratio.left.길이}`,
        this.denormalizedCoordinate[362],
        this.denormalizedCoordinate[359],
        leftEyeTextStyle,
      );
    }, 600);
  };

  drawBestRatio = () => {
    const rightEyeTextStyle = { x: 0, y: -76, fontSize: 18, color: "#00D7CA" };
    const leftEyeTextStyle = { x: 0, y: -76, fontSize: 18, color: "#00D7CA" };

    const rightEyeHeight = Calc.calculateDistance(
      { ...this.denormalizedCoordinate[159], y: this.denormalizedCoordinate[159].y - 4 },
      this.denormalizedCoordinate[145],
      "2d",
    );

    const leftEyeHeight = Calc.calculateDistance(
      { ...this.denormalizedCoordinate[386], y: this.denormalizedCoordinate[386].y - 4 },
      this.denormalizedCoordinate[374],
      "2d",
    );

    const leftAngle = Calc.calculateAngleWithVectors({
      pointA: this.denormalizedCoordinate[130],
      pointB: this.denormalizedCoordinate[133],
    });

    const rightAngle = Calc.calculateAngleWithVectors({
      pointA: this.denormalizedCoordinate[362],
      pointB: this.denormalizedCoordinate[359],
    });

    const rightEyeBestCoordinate = Calc.calculateCoordinateByAngleAndDirection(
      this.denormalizedCoordinate[133],
      rightAngle,
      rightEyeHeight * 3,
      "TOP_RIGHT",
    );

    const leftEyeBestCoordinate = Calc.calculateCoordinateByAngleAndDirection(
      this.denormalizedCoordinate[362],
      leftAngle,
      leftEyeHeight * 3,
      "TOP_LEFT",
    );

    this.drawLineBetweenPoints({
      point1: this.denormalizedCoordinate[133],
      point2: rightEyeBestCoordinate,
      lineStyle: BestRatioLineStyle,
      isDrawEndPoint: true,
    });

    this.drawLineBetweenPoints({
      point1: this.denormalizedCoordinate[362],
      point2: leftEyeBestCoordinate,
      lineStyle: BestRatioLineStyle,
      isDrawEndPoint: true,
    });

    setTimeout(() => {
      this.drawLineBetweenPoints({
        point1: { ...this.denormalizedCoordinate[159], y: this.denormalizedCoordinate[159].y - 4 },
        point2: this.denormalizedCoordinate[145],
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });

      this.drawLineBetweenPoints({
        point1: { ...this.denormalizedCoordinate[386], y: this.denormalizedCoordinate[386].y - 4 },
        point2: this.denormalizedCoordinate[374],
        lineStyle: DotLineStyle,
        isDrawEndPoint: true,
      });
    }, 400);

    setTimeout(() => {
      this.drawTextBetweenPoints(`${1} : ${3}`, this.denormalizedCoordinate[130], this.denormalizedCoordinate[133], rightEyeTextStyle);
      this.drawTextBetweenPoints(`${1} : ${3}`, this.denormalizedCoordinate[362], this.denormalizedCoordinate[359], leftEyeTextStyle);
    }, 600);
  };

  private calcAspectRatio = () => {
    const rightEyeRow = Calc.calculateDistance(this.denormalizedCoordinate[130], this.denormalizedCoordinate[133], "2d");
    const rightEyeHeight = Calc.calculateDistance(
      { ...this.denormalizedCoordinate[159], y: this.denormalizedCoordinate[159].y - 4 },
      this.denormalizedCoordinate[145],
      "2d",
    );

    const leftEyeRow = Calc.calculateDistance(this.denormalizedCoordinate[362], this.denormalizedCoordinate[359], "2d");
    const leftEyeHeight = Calc.calculateDistance(
      { ...this.denormalizedCoordinate[386], y: this.denormalizedCoordinate[386].y - 4 },
      this.denormalizedCoordinate[374],
      "2d",
    );

    const rightLengthRatio = Calc.calcRatio(rightEyeHeight, rightEyeRow);
    const leftLengthRatio = Calc.calcRatio(leftEyeHeight, leftEyeRow);

    return {
      left: { height: 1, length: leftLengthRatio },
      right: { height: 1, length: rightLengthRatio },
    };
  };

  private setEyesCoordinate = () => {
    //눈 쌍커풀 라인 4씩 낮춤
    const adjustRightEyeY = this.denormalizedCoordinate[159].y - 4;
    const adjustLeftEyeY = this.denormalizedCoordinate[145].y - 4;

    const rightEye = {
      rightTop: {
        x: this.denormalizedCoordinate[130].x,
        y: adjustRightEyeY,
        z: 0,
      },
      leftTop: {
        x: this.denormalizedCoordinate[133].x,
        y: adjustRightEyeY,
        z: 0,
      },
      rightBottom: {
        x: this.denormalizedCoordinate[130].x,
        y: this.denormalizedCoordinate[145].y,
        z: 0,
      },
      leftBottom: {
        x: this.denormalizedCoordinate[133].x,
        y: this.denormalizedCoordinate[145].y,
        z: 0,
      },
    };

    const leftEye = {
      leftTop: {
        x: this.denormalizedCoordinate[362].x,
        y: adjustLeftEyeY,
        z: 0,
      },
      rightTop: {
        x: this.denormalizedCoordinate[359].x,
        y: adjustLeftEyeY,
        z: 0,
      },
      leftBottom: {
        x: this.denormalizedCoordinate[362].x,
        y: this.denormalizedCoordinate[374].y,
        z: 0,
      },
      rightBottom: {
        x: this.denormalizedCoordinate[359].x,
        y: this.denormalizedCoordinate[374].y,
        z: 0,
      },
    };

    return { leftEye, rightEye };
  };
}
