import styled from "styled-components";
import DatePicker, { DatePickerProps } from "./index";
import moment from "moment";

interface DateRangePickerProps extends Omit<DatePickerProps, "date" | "onChange"> {
  startDate?: string;
  endDate?: string;
  showTimeSelect?: boolean;
  onChangeStart: (dateString?: string) => void;
  onChangeEnd: (dateString?: string) => void;
}

export const DateRangePicker = ({ ...props }: DateRangePickerProps) => {
  let startDate: any;
  if (props.startDate !== undefined) startDate = props.startDate ? moment(props.startDate).toDate() : undefined;

  let endDate: any;
  if (props.endDate !== undefined) endDate = props.endDate ? moment(props.endDate).toDate() : undefined;

  return (
    <DateRangePickerContainer>
      <DatePicker.Default
        placeHolderText={props.placeHolderText || "시작일"}
        date={moment(props.startDate).isValid() ? props.startDate : undefined}
        width={props.width}
        height={props.height}
        onChange={props.onChangeStart}
        dateFormat={props.dateFormat}
        isStartOfDay
        disabled={props.disabled}
        showTimeSelect={props.showTimeSelect}
        selectStarts
        selectsEnds
        startDate={startDate}
        endDate={endDate}
      />
      <DatePicker.Default
        placeHolderText={props.placeHolderText || "종료일"}
        date={moment(props.endDate).isValid() ? props.endDate : undefined}
        width={props.width}
        height={props.height}
        onChange={props.onChangeEnd}
        dateFormat={props.dateFormat}
        isEndOfDay
        disabled={props.disabled}
        showTimeSelect={props.showTimeSelect}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
      />
    </DateRangePickerContainer>
  );
};

const DateRangePickerContainer = styled.div`
  display: flex;
  gap: 8px;
`;
