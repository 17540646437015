import { AdRepositoryImpl } from "@/data/interactor/repository/AdRepositoryImpl";
import { AdRepository } from "@/domain/interactor/repository/AdRepository";
import { AnalyticsRepository } from "@/domain/interactor/repository/AnalyticsRepository";
import { DoctorRepository } from "@/domain/interactor/repository/DoctorRepository";
import { HospitalRepository } from "@/domain/interactor/repository/HospitalRepository";
import { LocalRepository } from "@/domain/interactor/repository/LocalRepository";
import { NoticeRepository } from "@/domain/interactor/repository/NoticeRepository";
import { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { SessionRepository } from "@/domain/interactor/repository/SessionRepository";
import { TokenRepository } from "@/domain/interactor/repository/TokenRepository";
import { UserRepository } from "@/domain/interactor/repository/UserRepository";
import { ContainerModule, interfaces } from "inversify";
import { AnalyticsRepositoryImpl } from "./AnalyticsRepositoryImpl";
import { DoctorRepositoryImpl } from "./DoctorRepositoryImpl";
import { HospitalRepositoryImpl } from "./HospitalRepositoryImpl";
import { TYPES } from "./index.container.type";
import { LocalRepositoryImpl } from "./LocalRepositoryImpl";
import { NoticeRepositoryImpl } from "./NoticeRepositoryImpl";
import { OperationConsultingRepositoryImpl } from "./OperationConsultingRepositoryImpl";
import { SessionRepositoryImpl } from "./SessionRepositoryImpl";
import { TokenRepositoryImpl } from "./TokenRepositoryImpl";
import { UserRepositoryImpl } from "./UserRepositoryImpl";

const repositories = new ContainerModule((bind: interfaces.Bind) => {
  bind<AdRepository>(TYPES.AdRepository).to(AdRepositoryImpl);
  bind<DoctorRepository>(TYPES.DoctorRepository).to(DoctorRepositoryImpl);
  bind<HospitalRepository>(TYPES.HospitalRepository).to(HospitalRepositoryImpl);
  bind<LocalRepository>(TYPES.LocalRepository).to(LocalRepositoryImpl);
  bind<NoticeRepository>(TYPES.NoticeRepository).to(NoticeRepositoryImpl);
  bind<OperationConsultingRepository>(TYPES.OperationConsultingRepository).to(OperationConsultingRepositoryImpl);
  bind<SessionRepository>(TYPES.SessionRepository).to(SessionRepositoryImpl);
  bind<TokenRepository>(TYPES.TokenRepository).to(TokenRepositoryImpl);
  bind<UserRepository>(TYPES.UserRepository).to(UserRepositoryImpl);
  bind<AnalyticsRepository>(TYPES.AnalyticsRepository).to(AnalyticsRepositoryImpl);
});

export default repositories;
