import { injectable, inject } from "inversify";
import { Observable } from "rxjs";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";
import {
  UpdateHospitalFaceFitConfiguration,
  UpdateHospitalFaceFitConfigurationOutput,
  UpdateHospitalFaceFitConfigurationInput,
} from "./model/UpdateHospitalFaceFitConfiguration";

@injectable()
export class UpdateHospitalFaceFitConfigurationImpl implements UpdateHospitalFaceFitConfiguration {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute = (input: UpdateHospitalFaceFitConfigurationInput): Observable<UpdateHospitalFaceFitConfigurationOutput> => {
    return this.operationConsultingRepository.updateHospitalFaceFitConfiguration(input);
  };
}
