import { DefaultDatePicker as Default } from "./DatePicker";
import { DateRangePicker as Range } from "./DateRangePicker";
// import { DefaultTimePicker as Time } from "./TimePicker";
import { TimeRangePicker as TimeRange } from "./TimeRangePicker";
import { DefaultTimePicker as Time } from "./TimePicker";
import * as V2 from "./v2";

export type DatePickerProps = {
  date?: string | Date;
  width?: string;
  height?: string;
  timeInterval?: number;
  dateFormat?: string;
  placeHolderText?: string;
  focusColor?: string;
  onChange: (date?: string, index?: number) => void;
  isISOFormat?: boolean;
  showTimeSelect?: boolean;
  isStartOfDay?: boolean;
  isEndOfDay?: boolean;
  timeFormat?: string;
  index?: string;
  disabled?: boolean;
  selectStarts?: boolean;
  selectsEnds?: boolean;
  startDate?: string;
  endDate?: string;
  minDate?: string;
  zIndex?: string;
  backgroundColor?: string;
};

export type TimePickerProps = {
  date?: string;
  width?: string;
  height?: string;
  timeInterval?: number;
  placeHolderText?: string;
  focusColor?: string;
  onChange: (index: number) => (date?: string) => void;
  isISOFormat?: boolean;
  isStartOfDay?: boolean;
  isEndOfDay?: boolean;
  timeFormat?: string;
  disabled?: boolean;
};

const DatePicker = {
  Default,
  Range,
  Time,
  TimeRange,
  V2,
};

export default DatePicker;
