import type { UISystemManager } from "@/application/ui-system/view-model/model";
import type { DeleteBestPractice } from "@/domain/usecase/consulting/model/DeleteBestPractice";
import type { EmbeddingExtractionBestPractice } from "@/domain/usecase/consulting/model/EmbeddingExtractionBestPractice";
import type { GetBestPractice } from "@/domain/usecase/consulting/model/GetBestPractice";
import type { GetTags } from "@/domain/usecase/consulting/model/GetTags";
import type { UpdateBestPractice } from "@/domain/usecase/consulting/model/UpdateBestPractice";
import type { UploadImage } from "@/domain/usecase/gcs/model/UploadImage";
import type { GetHospital } from "@/domain/usecase/hospital/model/GetHospital";
import type { GetOperationCategories } from "@/domain/usecase/hospital/model/GetOperationCategories";
import { BestPracticeDetail, OperationCategory, Tag } from "@view-data/FaceFit";
import { injectable } from "inversify";
import { BehaviorSubject, from, map, mergeMap, of, Subject, zip, asyncScheduler, observeOn } from "rxjs";
import { v4 } from "uuid";
import { BestPracticeEditViewModel } from "./model/BestPracticeEditViewModel";

@injectable()
export default class BestPracticeEditViewModelImpl implements BestPracticeEditViewModel {
  data: BestPracticeEditViewModel["data"] = {
    hospitalId: null,
    operationCategories: [],
    tags: [],
    bestPracticeDetail: null,
  };

  output: BestPracticeEditViewModel["output"] = {
    operationCategories: new BehaviorSubject<OperationCategory[]>([]),
    tags: new BehaviorSubject<Tag[]>([]),
    bestPracticeDetail: new BehaviorSubject<BestPracticeDetail | null>(null),
  };

  route: BestPracticeEditViewModel["route"] = {
    toBack: new Subject<void>(),
  };

  constructor(
    readonly bestPracticeId: number,
    readonly uiSystem: UISystemManager,
    private readonly ucGetHospital: GetHospital,
    private readonly ucUploadImage: UploadImage,
    private readonly ucGetTags: GetTags,
    private readonly ucGetOperationCategories: GetOperationCategories,
    private readonly ucGetBestPractice: GetBestPractice,
    private readonly ucUpdateBestPractice: UpdateBestPractice,
    private readonly ucDeleteBestPractice: DeleteBestPractice,
    private readonly ucEmbeddingExtractionBestPractice: EmbeddingExtractionBestPractice,
  ) {
    this.init(bestPracticeId);
  }

  input: BestPracticeEditViewModel["input"] = {
    clickUpdate: (bestPracticeDetail, extractEmbedding) => {
      if (this.data.bestPracticeDetail) {
        this.uiSystem.loadingHandler.backdropLoading.next(true);
        bestPracticeDetail.categories.map((category) => {
          const found = this.data.bestPracticeDetail?.categories.find((c) => c.categoryId === category.categoryId);
          if (found) return found;
          return category;
        });
        this.ucUpdateBestPractice
          .execute({
            bestPracticeId: this.bestPracticeId,
            exposure: bestPracticeDetail.exposure,
            publicData: bestPracticeDetail.publicData ?? false,
            gender: bestPracticeDetail.gender,
            elapsedTime: bestPracticeDetail.elapsedTime,
            customerLabel: bestPracticeDetail.customerLabel,
            photos: bestPracticeDetail.photos.filter((photo) => photo.uploaded),
            categories: bestPracticeDetail.categories.map((category) => {
              const found = this.data.bestPracticeDetail?.categories.find((c) => c.categoryId === category.categoryId);
              if (found) return found;
              return category;
            }),
            tags: bestPracticeDetail.tags.map((tag) => {
              const found = this.data.bestPracticeDetail?.tags.find((t) => t.tagId === tag.tagId);
              if (found) return found;
              return tag;
            }),
          })
          .subscribe({
            next: () => {
              if (extractEmbedding) {
                this.ucEmbeddingExtractionBestPractice
                  .execute({
                    bestPracticeId: this.bestPracticeId,
                  })
                  .pipe(observeOn(asyncScheduler, 1000))
                  .subscribe({
                    next: () => {
                      this.uiSystem.toastHandler.toast.next({
                        message: "유사도 추출에 성공했습니다.",
                        position: { vertical: "top", horizontal: "center" },
                        type: "SUCCESS",
                      });
                      const customEvent = new CustomEvent("embedSuccessEvent");
                      window.dispatchEvent(customEvent);
                    },
                    error: () => {
                      this.uiSystem.toastHandler.toast.next({
                        message: "유사도 추출에 실패했습니다.",
                        position: { vertical: "top", horizontal: "center" },
                        type: "ERROR",
                      });
                    },
                  });
              }
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              this.uiSystem.toastHandler.toast.next({
                message: "정보가 수정되었습니다.",
                position: { vertical: "top", horizontal: "center" },
                type: "SUCCESS",
              });
              this.route.toBack.next();
            },
            error: (error) => {
              this.uiSystem.loadingHandler.backdropLoading.next(false);
              this.uiSystem.toastHandler.toast.next({
                message: "상태변경에 실패했습니다.",
                position: { vertical: "top", horizontal: "center" },
                type: "ERROR",
              });
              //   return this.uiSystem.errorHandler.AnonymousError.next({ err: `${JSON.stringify(error)}` });
            },
          });
      }
    },
    clickCancel: () => {
      this.uiSystem.popupHandler.alert.confirm.next({
        open: true,
        infoMessage: "수정을 취소하겠습니까?",
        confirm: () => {
          this.route.toBack.next();
          this.uiSystem.popupHandler.alert.confirm.next({ open: false });
        },
      });
    },
    clickDelete: () => {
      this.uiSystem.popupHandler.alert.confirm.next({
        open: true,
        infoMessage: `전후사진을 삭제 하시겠습니까?\n삭제된 데이터는 복구되지않습니다.`,
        confirm: () => {
          this.ucDeleteBestPractice.execute({ bestPracticeId: this.bestPracticeId }).subscribe({
            next: () => {
              this.uiSystem.popupHandler.alert.confirm.next({ open: false });
              this.route.toBack.next();
              this.uiSystem.toastHandler.toast.next({
                message: "전후사진이 삭제되었습니다.",
                position: { vertical: "top", horizontal: "center" },
                type: "SUCCESS",
              });
            },
          });
        },
      });
    },
  };

  event: BestPracticeEditViewModel["event"] = {
    uploadPhotos: (newPhotos) =>
      from(newPhotos.filter(({ file, uploaded }) => !uploaded && file !== null)).pipe(
        mergeMap((photo) =>
          zip(
            of(photo),
            this.ucUploadImage.execute({
              public: false,
              file: photo.file!,
              bucketPath: `/dr-wo/hospitals/${this.data.hospitalId}/best-practice-photos`,
              ordinal: 1,
              type: "jpeg",
            }),
          ),
        ),
        map(([photo, output]) => {
          console.log(photo, output);
          return {
            ...photo,
            originUrl: output.originUrl.replace("https://storage.googleapis.com/", "https://storage.cloud.google.com/"),
            resizedUrl: output.resizedUrl?.replace("https://storage.googleapis.com/", "https://storage.cloud.google.com/") ?? "",
            file: null,
            uploaded: true,
          };
        }),
      ),
  };

  private init = (bestPracticeId: number) => {
    zip(
      this.ucGetHospital.execute({
        infoTypes: ["ACCOUNTS", "PROFILE"],
      }),
      this.ucGetBestPractice.execute({ bestPracticeId: bestPracticeId }),
      this.ucGetOperationCategories.execute(),
      this.ucGetTags.execute(),
    ).subscribe({
      next: ([hospital, bestPractice, { items: categories }, { tags }]) => {
        // this.data.hospitalProfile = hospital;
        this.data.operationCategories = categories;
        this.data.tags = tags;
        this.data.bestPracticeDetail = {
          id: bestPractice.id,
          hospitalScopedId: bestPractice.hospitalScopedId,
          exposure: bestPractice.photos.some((photo) => photo.exposure),
          gender: bestPractice.gender,
          publicData: bestPractice.publicData,
          elapsedTime: bestPractice.elapsedTime,
          customerLabel: bestPractice.customerLabel,
          photos: bestPractice.photos.map((photo) => ({
            ...photo,
            filename: null,
            tempId: v4(),
            file: null,
            uploaded: true,
          })),
          categories: bestPractice.categories.map((category) => ({
            id: category.id,
            categoryId: category.operationCategoryId,
            subjectCode: category.subjectCode,
            operationName: category.operationName,
          })),
          tags: bestPractice.tags.map((tag) => ({
            id: tag.id,
            tagId: tag.tagId,
            name: tag.name,
          })),
        };

        this.data.hospitalId = hospital.id;
        // this.output.hospitalProfile.next(this.data.hospitalProfile);
        this.output.operationCategories.next(this.data.operationCategories);
        this.output.tags.next(this.data.tags);
        this.output.bestPracticeDetail.next(this.data.bestPracticeDetail);
      },
      error: (error) => {
        console.log(error);
        alert("전후사진 데이터를 기져오지 못했습니다.");
      },
    });
  };
}
