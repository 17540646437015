import { TYPES } from "@/data/interactor/repository/index.container.type";
import type { AnalyticsRepository } from "@/domain/interactor/repository/AnalyticsRepository";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import {
  GetConsultingAnalyticsFirstCreatedDate,
  GetConsultingAnalyticsFirstCreatedDateOutput,
} from "./model/GetConsultingAnalyticsFirstCreatedDate";

@injectable()
export class GetConsultingAnalyticsFirstCreatedDateImpl implements GetConsultingAnalyticsFirstCreatedDate {
  constructor(@inject(TYPES.AnalyticsRepository) private readonly analyticsRepository: AnalyticsRepository) {}

  execute(): Observable<GetConsultingAnalyticsFirstCreatedDateOutput> {
    return this.analyticsRepository.getOperationConsultingStartAt();
  }
}
