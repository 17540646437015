import styled from "styled-components";
import { DefaultDatePicker } from "./DatePicker";
import moment from "moment";

interface DatePickerProps {
  date?: string | Date;
  width?: string;
  height?: string;
  timeInterval?: number;
  dateFormat?: string;
  placeHolderText?: string;
  focusColor?: string;
  onChange: (date?: string, index?: number) => void;
  isISOFormat?: boolean;
  showTimeSelect?: boolean;
  isStartOfDay?: boolean;
  isEndOfDay?: boolean;
  timeFormat?: string;
  index?: string;
  disabled?: boolean;
  selectStarts?: boolean;
  selectsEnds?: boolean;
  startDate?: string;
  endDate?: string;
  minDate?: string;
  zIndex?: string;
  backgroundColor?: string;
}

interface DateRangePickerProps extends Omit<DatePickerProps, "date" | "onChange"> {
  disableSelectFuture: boolean;
  dateRangeMaxGap?: number;
  startDate?: string;
  endDate?: string;
  showTimeSelect?: boolean;
  onChangeStart: (dateString?: string) => void;
  onChangeEnd: (dateString?: string) => void;
}

const getDateBefore = (startDate: Date, daysAgo: number): Date => {
  const resultDate = new Date(startDate); // startDate를 복사하여 변경하지 않도록 함
  resultDate.setDate(resultDate.getDate() - daysAgo - 1); // daysAgo만큼 날짜를 뺀다
  return resultDate;
};

const DateRangePicker = ({ ...props }: DateRangePickerProps) => {
  let startDate: any;
  if (props.startDate !== undefined) startDate = props.startDate ? moment(props.startDate).toDate() : undefined;

  let endDate: any;
  if (props.endDate !== undefined) endDate = props.endDate ? moment(props.endDate).toDate() : undefined;

  const minDate: any =
    props.dateRangeMaxGap && props.endDate ? getDateBefore(moment(props.endDate).toDate(), props.dateRangeMaxGap) : undefined;

  return (
    <DateRangePickerContainer>
      <DefaultDatePicker
        placeHolderText={props.placeHolderText || "시작일"}
        date={moment(props.startDate).isValid() ? props.startDate : undefined}
        width={props.width}
        height={props.height}
        dateFormat={props.dateFormat}
        isStartOfDay
        disabled={props.disabled}
        showTimeSelect={props.showTimeSelect}
        selectStarts
        selectsEnds
        startDate={startDate}
        endDate={endDate}
        backgroundColor={props.backgroundColor}
        minDate={minDate}
        maxDate={props.disableSelectFuture ? new Date() : undefined}
        onChange={props.onChangeStart}
      />
      <DefaultDatePicker
        placeHolderText={props.placeHolderText || "종료일"}
        date={moment(props.endDate).isValid() ? props.endDate : undefined}
        width={props.width}
        height={props.height}
        dateFormat={props.dateFormat}
        isEndOfDay
        disabled={props.disabled}
        showTimeSelect={props.showTimeSelect}
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        backgroundColor={props.backgroundColor}
        maxDate={props.disableSelectFuture ? new Date() : undefined}
        onChange={props.onChangeEnd}
      />
    </DateRangePickerContainer>
  );
};

const DateRangePickerContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export default DateRangePicker;
