import React from "react";
import * as Widgets from "@/application/view/widget";

interface BasicInputProps {
  value?: string;
  placeholder: string;
  width?: string;
  height?: string;
  onChange?: (value: string) => void;
  onEnter?: () => void;
}

const BasicInput: React.FC<BasicInputProps> = ({ value, placeholder, width = "130px", height = "36px", onChange, onEnter }) => {
  return (
    <Widgets.Input.InputField
      value={value}
      type="text"
      placeholder={placeholder}
      width={width}
      height={height}
      backgroundColor={"#FAFDFF"}
      placeholderColor="#ABABAB"
      onChange={onChange}
      onSubmit={onEnter}
    />
  );
};

export default BasicInput;
