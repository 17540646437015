import type { UISystemManager } from "@/application/ui-system/view-model/model";
import type { CreateBestPractice } from "@/domain/usecase/consulting/model/CreateBestPractice";
import type { EmbeddingExtractionBestPractice } from "@/domain/usecase/consulting/model/EmbeddingExtractionBestPractice";
import type { GetTags } from "@/domain/usecase/consulting/model/GetTags";
import type { UploadImage } from "@/domain/usecase/gcs/model/UploadImage";
import type { GetHospital } from "@/domain/usecase/hospital/model/GetHospital";
import type { GetOperationCategories } from "@/domain/usecase/hospital/model/GetOperationCategories";
import { BestPracticeDetail, OperationCategory, Tag } from "@view-data/FaceFit";
import { injectable } from "inversify";
import { asyncScheduler, BehaviorSubject, from, map, mergeMap, observeOn, of, Subject, zip } from "rxjs";
import { BestPracticeAddViewModel } from "./model/BestPracticeAddViewModel";

@injectable()
export default class BestPracticeAddViewModelImpl implements BestPracticeAddViewModel {
  data: BestPracticeAddViewModel["data"] = {
    hospitalId: null,
    operationCategories: [],
    tags: [],
    bestPracticeDetail: null,
  };

  output: BestPracticeAddViewModel["output"] = {
    operationCategories: new BehaviorSubject<OperationCategory[]>([]),
    tags: new BehaviorSubject<Tag[]>([]),
    bestPracticeDetail: new BehaviorSubject<BestPracticeDetail | null>(null),
  };

  route: BestPracticeAddViewModel["route"] = {
    toBack: new Subject<void>(),
    toBestPracticeList: new Subject<{ subjectCode: string }>(),
  };

  constructor(
    readonly uiSystem: UISystemManager,
    private readonly ucGetHospital: GetHospital,
    private readonly ucUploadImage: UploadImage,
    private readonly ucGetTags: GetTags,
    private readonly ucGetOperationCategories: GetOperationCategories,
    private readonly ucCreateBestPractice: CreateBestPractice,
    private readonly ucEmbeddingExtractionBestPractice: EmbeddingExtractionBestPractice,
  ) {
    this.init();
  }

  input: BestPracticeAddViewModel["input"] = {
    clickCreate: (bestPracticeDetail, mainSubject, extractEmbedding) => {
      this.uiSystem.loadingHandler.backdropLoading.next(true);
      bestPracticeDetail.categories.map((category) => {
        const found = this.data.bestPracticeDetail?.categories.find((c) => c.categoryId === category.categoryId);
        if (found) return found;
        return category;
      });
      this.ucCreateBestPractice
        .execute({
          exposure: bestPracticeDetail.exposure,
          publicData: bestPracticeDetail.publicData ?? false,
          gender: bestPracticeDetail.gender,
          elapsedTime: bestPracticeDetail.elapsedTime,
          customerLabel: bestPracticeDetail.customerLabel,
          photos: bestPracticeDetail.photos.filter((photo) => photo.uploaded),
          categories: bestPracticeDetail.categories.map((category) => {
            const found = this.data.bestPracticeDetail?.categories.find((c) => c.categoryId === category.categoryId);
            if (found) return found;
            return category;
          }),
          tags: bestPracticeDetail.tags.map((tag) => {
            const found = this.data.bestPracticeDetail?.tags.find((t) => t.tagId === tag.tagId);
            if (found) return found;
            return tag;
          }),
        })
        .subscribe({
          next: (output) => {
            if (extractEmbedding) {
              this.ucEmbeddingExtractionBestPractice
                .execute({
                  bestPracticeId: output.bestPracticeId,
                })
                .pipe(observeOn(asyncScheduler, 1000))
                .subscribe({
                  next: () => {
                    this.uiSystem.toastHandler.toast.next({
                      message: "유사도 추출에 성공했습니다.",
                      position: { vertical: "top", horizontal: "center" },
                      type: "SUCCESS",
                    });
                    const customEvent = new CustomEvent("embedSuccessEvent");
                    window.dispatchEvent(customEvent);
                  },
                  error: () => {
                    this.uiSystem.toastHandler.toast.next({
                      message: "유사도 추출에 실패했습니다.",
                      position: { vertical: "top", horizontal: "center" },
                      type: "ERROR",
                    });
                  },
                });
            }
            this.uiSystem.loadingHandler.backdropLoading.next(false);
            this.uiSystem.toastHandler.toast.next({
              message: "정보가 생성되었습니다.",
              position: { vertical: "top", horizontal: "center" },
              type: "SUCCESS",
            });
            this.route.toBestPracticeList.next({
              subjectCode: mainSubject,
            });
          },
          error: (error) => {
            this.uiSystem.loadingHandler.backdropLoading.next(false);
            this.uiSystem.toastHandler.toast.next({
              message: "생성에 실패했습니다.",
              position: { vertical: "top", horizontal: "center" },
              type: "ERROR",
            });
          },
        });
    },
    clickCancel: () => {
      this.uiSystem.popupHandler.alert.confirm.next({
        open: true,
        infoMessage: "생성을 취소하겠습니까?",
        confirm: () => {
          this.route.toBack.next();
          this.uiSystem.popupHandler.alert.confirm.next({ open: false });
        },
      });
    },
  };

  event: BestPracticeAddViewModel["event"] = {
    uploadPhotos: (newPhotos) =>
      from(newPhotos.filter(({ file, uploaded }) => !uploaded && file !== null)).pipe(
        mergeMap((photo) =>
          zip(
            of(photo),
            this.ucUploadImage.execute({
              public: false,
              file: photo.file!,
              bucketPath: `/dr-wo/hospitals/${this.data.hospitalId}/best-practice-photos`,
              ordinal: 1,
              type: "jpeg",
            }),
          ),
        ),
        map(([photo, output]) => {
          console.log(photo, output);
          return {
            ...photo,
            originUrl: output.originUrl.replace("https://storage.googleapis.com/", "https://storage.cloud.google.com/"),
            resizedUrl: output.resizedUrl?.replace("https://storage.googleapis.com/", "https://storage.cloud.google.com/") ?? "",
            file: null,
            uploaded: true,
          };
        }),
      ),
  };

  private init = () => {
    zip(
      this.ucGetHospital.execute({
        infoTypes: ["ACCOUNTS", "PROFILE"],
      }),
      this.ucGetOperationCategories.execute(),
      this.ucGetTags.execute(),
    ).subscribe({
      next: ([hospital, { items: categories }, { tags }]) => {
        // this.data.hospitalProfile = hospital;
        this.data.operationCategories = categories;
        this.data.tags = tags;
        this.data.hospitalId = hospital.id;
        // this.output.hospitalProfile.next(this.data.hospitalProfile);
        this.output.operationCategories.next(this.data.operationCategories);
        this.output.tags.next(this.data.tags);
        this.output.bestPracticeDetail.next(this.data.bestPracticeDetail);
      },
      error: (error) => {
        console.log(error);
        alert("전후사진 데이터를 기져오지 못했습니다.");
      },
    });
  };
}
