import type {
  ConsultingData,
  GetConsultingAnalyticsGroupByConsultant,
  GetConsultingAnalyticsGroupByConsultantOutput,
} from "@/domain/usecase/analytics/model/GetConsultingAnalyticsGroupByConsultant";
import type {
  GetConsultingAnalyticsGroupByDate,
  GetConsultingAnalyticsGroupByDateOutput,
} from "@/domain/usecase/analytics/model/GetConsultingAnalyticsGroupByDate";
import {
  ConsultantPerformanceData,
  ConsultantPerformanceDataGroupedTimeStamp,
  DailyGroupedConsultantPerformanceData,
  HospitalAnalyticsSearchOption,
} from "@view-data/Analytics";
import { injectable } from "inversify";
import { mergeMap, Subject } from "rxjs";
import type { UISystemManager } from "../ui-system/view-model/model";
import { ConsultantAnalyticsViewModel } from "./model/ConsultantAnalyticsViewModel";

@injectable()
export class ConsultantAnalyticsViewModelImpl implements ConsultantAnalyticsViewModel {
  data: ConsultantAnalyticsViewModel["data"] = {
    searchOption: {},
    consultantPerformanceData: [],
  };
  output: ConsultantAnalyticsViewModel["output"] = {
    searchOption: new Subject<HospitalAnalyticsSearchOption>(),
    consultantPerformanceData: new Subject<ConsultantPerformanceData[]>(),
    tableData: new Subject<ConsultantPerformanceDataGroupedTimeStamp[]>(),
  };

  constructor(
    readonly uiSystem: UISystemManager,
    private readonly ucGetConsultingAnalyticsGroupByConsultant: GetConsultingAnalyticsGroupByConsultant,
    private readonly ucGetConsultingAnalyticsGroupByDate: GetConsultingAnalyticsGroupByDate,
  ) {}

  input: ConsultantAnalyticsViewModel["input"] = {
    resetSearchOption: () => {
      this.data.searchOption = {};
      this.output.searchOption.next(this.data.searchOption);
    },
    selectSearchOption: (option: HospitalAnalyticsSearchOption) => {
      this.data.searchOption = {
        ...option,
      };
      this.output.searchOption.next(this.data.searchOption);
    },
    clickSearch: () => {
      if (this.data.searchOption.startDate && this.data.searchOption.endDate) {
        const { startDate, endDate } = this.data.searchOption;
        this.uiSystem.loadingHandler.backdropLoading.next(true);
        this.ucGetConsultingAnalyticsGroupByConsultant
          .execute({
            start: this.formatDateStringToYYYYMMDD(startDate),
            end: this.formatDateStringToYYYYMMDD(endDate),
          })
          .pipe(
            mergeMap((output) => {
              this.data.consultantPerformanceData = this.extractConsultantPerformanceData(output);
              this.output.consultantPerformanceData.next(this.data.consultantPerformanceData);

              this.uiSystem.loadingHandler.backdropLoading.next(false);

              return this.ucGetConsultingAnalyticsGroupByDate.execute({
                start: this.formatDateStringToYYYYMMDD(startDate),
                end: this.formatDateStringToYYYYMMDD(endDate),
              });
            }),
          )
          .subscribe({
            next: (output) => {
              this.output.tableData.next(
                this.generateConsultantDailyPerformanceDataGroupedTimeStamp(output, this.data.consultantPerformanceData),
              );
              this.uiSystem.loadingHandler.backdropLoading.next(false);
            },
            error: () => {
              this.uiSystem.loadingHandler.backdropLoading.next(false);
            },
          });
      }
    },
  };

  private formatDateStringToYYYYMMDD = (dateString: string): string => {
    // 입력값이 유효한지 검사
    const date = new Date(dateString);

    // Date 객체가 유효하지 않으면 빈 문자열 반환
    if (isNaN(date.getTime())) {
      return "";
    }

    // 년, 월, 일 추출
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 1을 더해줌
    const day = String(date.getDate()).padStart(2, "0"); // 날짜가 한 자릿수일 경우 0을 채워줌

    // YYYYMMDD 형식으로 반환
    return `${year}${month}${day}`;
  };

  private extractConsultantPerformanceData = (output: GetConsultingAnalyticsGroupByConsultantOutput): ConsultantPerformanceData[] => {
    const performanceMap = new Map<number, ConsultantPerformanceData>();

    output.items.forEach((item) => {
      const processData = (data: ConsultingData[], type: "consultCount" | "reservationCount" | "completedCount") => {
        data.forEach((consulting) => {
          const { consultantId, consultantName } = consulting;

          // Map에서 해당 상담사의 데이터를 가져오거나 초기화
          if (!performanceMap.has(consultantId)) {
            performanceMap.set(consultantId, {
              consultantId,
              name: consultantName,
              consultCount: 0,
              reservationCount: 0,
              completedCount: 0,
            });
          }

          // 업데이트
          const currentPerformance = performanceMap.get(consultantId)!;
          currentPerformance[type] += 1;
        });
      };

      // 각 카테고리별 데이터를 처리
      processData(item.consulted, "consultCount");
      processData(item.reserved, "reservationCount");
      processData(item.completed, "completedCount");
    });

    // Map의 값을 배열로 변환하여 반환
    return Array.from(performanceMap.values());
  };

  private generateConsultantDailyPerformanceDataGroupedTimeStamp = (
    output: GetConsultingAnalyticsGroupByDateOutput,
    baseConsultantPerformanceData: ConsultantPerformanceData[],
  ): ConsultantPerformanceDataGroupedTimeStamp[] => {
    const result: ConsultantPerformanceDataGroupedTimeStamp[] = [];

    for (const dailyData of output.items) {
      const consultantPerformance: DailyGroupedConsultantPerformanceData[] = baseConsultantPerformanceData.map((baseConsultant) => {
        const consultCount = dailyData.consulted.filter((item) => item.consultantId === baseConsultant.consultantId).length;
        const reservationCount = dailyData.reserved.filter((item) => item.consultantId === baseConsultant.consultantId).length;
        const completedCount = dailyData.completed.filter((item) => item.consultantId === baseConsultant.consultantId).length;

        return {
          consultantId: baseConsultant.consultantId,
          name: baseConsultant.name,
          consultCount: consultCount || null,
          reservationCount: reservationCount || null,
          completedCount: completedCount || null,
        };
      });

      result.push({
        timeStamp: this.parseDateStringTODate(dailyData.date),
        consultantPerformance,
      });
    }

    return result;
  };

  private parseDateStringTODate = (yyyymmdd: string): Date => {
    if (yyyymmdd.length !== 8 || isNaN(Number(yyyymmdd))) {
      throw new Error("Invalid YYYYMMDD format");
    }

    const year = parseInt(yyyymmdd.slice(0, 4), 10);
    const month = parseInt(yyyymmdd.slice(4, 6), 10) - 1; // 월은 0부터 시작 (0 = 1월)
    const day = parseInt(yyyymmdd.slice(6, 8), 10);

    return new Date(year, month, day);
  };
}
