import { injectable, inject } from "inversify";
import { Observable } from "rxjs";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";
import {
  GetConsultingNoteByNoteNumber,
  GetConsultingNoteByNoteNumberInput,
  GetConsultingNoteByNoteNumberOutput,
} from "./model/GetConsultingNotebyNoteNumber";

@injectable()
export class GetConsultingNoteByNoteNumberImpl implements GetConsultingNoteByNoteNumber {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: GetConsultingNoteByNoteNumberInput): Observable<GetConsultingNoteByNoteNumberOutput> {
    return this.operationConsultingRepository.getConsultingNoteByNoteNumber(input.noteNumber);
  }
}
