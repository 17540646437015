import { TYPES } from "@/data/interactor/repository/index.container.type";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import { GetBestPractices, GetBestPracticesInput, GetBestPracticesOutput } from "./model/GetBestPractices";

@injectable()
export class GetBestPracticesImpl implements GetBestPractices {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: GetBestPracticesInput): Observable<GetBestPracticesOutput> {
    return this.operationConsultingRepository.getBestPractice(
      input.page,
      input.size,
      input.keyword,
      input.gender,
      input.categories,
      input.tags,
      input.exposure,
    );
  }
}
