import { injectable, inject } from "inversify";
import { Observable } from "rxjs";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";
import { GetBestPractice, GetBestPracticeInput, GetBestPracticeOutput } from "./model/GetBestPractice";

@injectable()
export class GetBestPracticeImpl implements GetBestPractice {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: GetBestPracticeInput): Observable<GetBestPracticeOutput> {
    return this.operationConsultingRepository.getBestPracticeDetail(input.bestPracticeId);
  }
}
