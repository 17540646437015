import { TYPES } from "@/data/interactor/repository/index.container.type";
import type { AnalyticsRepository } from "@/domain/interactor/repository/AnalyticsRepository";
import { inject, injectable } from "inversify";
import { Observable } from "rxjs";
import {
  GetConsultingAnalyticsGroupByConsultant,
  GetConsultingAnalyticsGroupByConsultantInput,
  GetConsultingAnalyticsGroupByConsultantOutput,
} from "./model/GetConsultingAnalyticsGroupByConsultant";

@injectable()
export class GetConsultingAnalyticsGroupByConsultantImpl implements GetConsultingAnalyticsGroupByConsultant {
  constructor(@inject(TYPES.AnalyticsRepository) private readonly analyticsRepository: AnalyticsRepository) {}

  execute(input: GetConsultingAnalyticsGroupByConsultantInput): Observable<GetConsultingAnalyticsGroupByConsultantOutput> {
    return this.analyticsRepository.getOperationConsultingAnalyticsGroupByConsultant(input.start, input.end);
  }
}
