import { injectable, inject } from "inversify";
import { Observable } from "rxjs";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";
import { CreateBestPractice, CreateBestPracticeInput, CreateBestPracticeOutput } from "./model/CreateBestPractice";

@injectable()
export class CreateBestPracticeImpl implements CreateBestPractice {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: CreateBestPracticeInput): Observable<CreateBestPracticeOutput> {
    return this.operationConsultingRepository.createBestPractice(input);
  }
}
