import { injectable, inject } from "inversify";
import { Observable } from "rxjs";
import type { OperationConsultingRepository } from "@/domain/interactor/repository/OperationConsultingRepository";
import { TYPES } from "@/data/interactor/repository/index.container.type";
import { DeleteBestPractice, DeleteBestPracticeInput, DeleteBestPracticeOutput } from "./model/DeleteBestPractice";

@injectable()
export class DeleteBestPracticeImpl implements DeleteBestPractice {
  constructor(@inject(TYPES.OperationConsultingRepository) private readonly operationConsultingRepository: OperationConsultingRepository) {}

  execute(input: DeleteBestPracticeInput): Observable<DeleteBestPracticeOutput> {
    return this.operationConsultingRepository.deleteBestPractice(input.bestPracticeId);
  }
}
