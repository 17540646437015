import * as Entity from "@/domain/entity";
import { AnalysisStatus } from "@/domain/entity/consulting/consulting.entity";
import { PersonaShiftPart, PersonaShiftStyle } from "@/domain/entity/ai-analysis/aiAnalysis.entity";
export interface FaceScanDisplayStatus {
  before: boolean;
  best: boolean;
}

export type FaceScanCategory = "EYE_GOLDEN_RATIO" | "EYE_BALANCE" | "EYE_TAILS" | "FACIAL_PROPORTIONS" | "FACIAL_SYMMETRY_ANGLE";

export type FaceScanDisplayOption = "ALL" | "BEFORE" | "BEST";

export type ServiceType = "before-and-after" | "before" | "after" | "face-style";

export type BEST_PRACTICE_PHOTO_TYPE = "BEFORE" | "AFTER";
export type BEST_PRACTICE_PHOTO_KIND =
  | "NONE"
  | "FACE_FRONTAL"
  | "FACE_FRONTAL_EYES_UP"
  | "FACE_FRONTAL_EYES_DOWN"
  | "FACE_RIGHT_EYES"
  | "FACE_LEFT_EYES"
  | "FACE_RIGHT_NOSE"
  | "FACE_LEFT_NOSE";
export type BEST_PRACTICE_GENDER = "MALE" | "FEMALE";

export interface AutoCompleteOption {
  id: string;
  label: string;
}

export interface Filter {
  category: FilterCategoryName;
  isSelected: boolean;
}

export interface BestPractice {
  id: number;
  publicData: boolean | null;
  beforeAndAfterItems: BeforeAndAfter[];
  ratio?: number;
  operationCategories: OperationCategory[];
  label: string | null;
  elapsedTime: string | null;
  tags: Tag[];
}

export interface BestPracticeConfig {
  id: number;
  hospitalScopedId: string;
  categories: string[];
  customerLabel: string | null;
  publicData: boolean | null;
  gender: Entity.Type.GENDER;
  photos: BestPracticeConfigPhoto[];
  tags: { id: number; tagId: number; name: string; ordinal: number }[];
  elapsedTime: string | null;
}

export interface BestPracticeConfigPhoto {
  id: number;
  exposure: boolean;
  ordinal: number;
  photo: string;
  type: "BEFORE" | "AFTER";
  kind: BEST_PRACTICE_PHOTO_KIND;
  lastEmbeddedAt: Date | null;
}

export interface BestPracticeDetail {
  id: number;
  hospitalScopedId: string;
  exposure: boolean;
  gender: Entity.Type.GENDER;
  elapsedTime: string | null;
  customerLabel: string | null;
  publicData: boolean | null;
  photos: BestPracticePhotoForUpdate[];
  categories: {
    id?: number | null;
    categoryId: number;
    subjectCode: Entity.Type.SUBJECT_CODE;
    operationName: string;
  }[];
  tags: {
    id?: number | null;
    tagId: number;
    name: string;
  }[];
}

export interface BestPracticePhotoForUpdate {
  id?: number | null;
  tempId: string;
  ordinal: number;
  filename: string | null;
  type: BEST_PRACTICE_PHOTO_TYPE;
  kind: BEST_PRACTICE_PHOTO_KIND | "NONE";
  originUrl: string;
  resizedUrl: string | null;
  file: File | null;
  uploaded: boolean;
}

export interface BeforeAndAfter {
  before: Photo | null;
  after: Photo | null;
}

export interface Photo {
  id: number;
  ordinal: number;
  originURL: string;
  objURL: string | null;
  type: "BEFORE" | "AFTER";
  kind: Entity.Type.BestPracticePhotoKind;
}

export interface OperationCategory {
  id: number;
  operationName: string;
  subjectCode: Entity.Type.SUBJECT_CODE;
  ordinal: number;
}

export interface FaceShiftOption {
  eye: string[];
  nose: string[];
  contour: string[];
}

export interface PersonaShift {
  id: number | null;
  code: "PERSONA_SHIFT";
  photoId: number | null;
  status: AnalysisStatus | null;
  result: {
    imageURL: string;
    objURL?: string;
    operations: { part: PersonaShiftPart; styles: PersonaShiftStyle[] }[];
  } | null;
  isSelected: boolean;
  isShow: boolean;
}

export type FilterCategoryName = "전체" | "매몰" | "절개" | "눈매교정" | "트임" | "상.하안검" | "눈밑" | "거상" | "지방이식" | "재수술";

export const GENDER_FILTERS: { gender: string; isSelected: boolean }[] = [
  { gender: "여성", isSelected: true },
  { gender: "남성", isSelected: false },
];

export const EYES_FILTERS: Filter[] = [
  { category: "전체", isSelected: true },
  { category: "매몰", isSelected: true },
  { category: "절개", isSelected: true },
  { category: "눈매교정", isSelected: true },
  { category: "트임", isSelected: true },
  { category: "상.하안검", isSelected: true },
  { category: "눈밑", isSelected: true },
  { category: "거상", isSelected: true },
  { category: "지방이식", isSelected: true },
  // { category: "재수술", isSelected: true },
];

export interface HospitalOperationCategory {
  id: number;
  operationName: string;
  subjectCode: Entity.Type.SUBJECT_CODE;
  filters: FilterCategoryName[];
}

export interface Tag {
  id: number;
  name: string;
  ordinal: number;
  subjectCode: Entity.Type.SUBJECT_CODE | null;
}

export interface FaceScanCanvasConfig {
  photo: HTMLImageElement;
  beforeCanvas: HTMLCanvasElement;
  bestCanvas: HTMLCanvasElement;
}

export interface NoseScanCanvasConfig {
  photo: HTMLImageElement;
  beforeCanvas: HTMLCanvasElement;
  bestCanvas: HTMLCanvasElement;
  beforeFrontalFaceCanvas: HTMLCanvasElement;
  bestFrontalFaceCanvas: HTMLCanvasElement;
}

export interface EyeScanResults {
  goldenRatio: ScanResults;
  asymmetry: ScanResults;
  aspectRatio: ScanResults;
  eyeTail: ScanResults;
}

export interface EyeScanVisibility {
  goldenRatio: CanvasVisibility;
  asymmetry: CanvasVisibility;
  aspectRatio: CanvasVisibility;
  tailAngle: CanvasVisibility;
}

export interface NoseScanResults {
  noseHeight: ScanResults;
  noseLength: ScanResults;
  foreheadNoseAngle: ScanResults;
  nasolabialAngle: ScanResults;
  noseTipShape: ScanResults;
  noseBridgeWidth: ScanResults;
  columellarLobularAngle: ScanResults;
}

export interface FaceContourScanResults {
  faceShapeType: ScanResults;
  zygomaticRatio: ScanResults;
  mandibularRatio: ScanResults;
  symmetryRatio: ScanResults;
}

export interface NoseScanVisibility {
  noseHeight: CanvasVisibility;
  noseLength: CanvasVisibility;
  foreheadNoseAngle: CanvasVisibility;
  nasolabialAngle: CanvasVisibility;
  noseTipShape: CanvasVisibility;
  noseBridgeWidth: CanvasVisibility;
  columellarLobularAngle: CanvasVisibility;
}

export interface FaceContourVisibility {
  faceShapeType: CanvasVisibility;
  zygomaticRatio: CanvasVisibility;
  mandibularRatio: CanvasVisibility;
  symmetryRatio: CanvasVisibility;
}

export interface ScanResults {
  before: string | null;
  best: string;
}

export interface CanvasVisibility {
  before: boolean;
  best: boolean;
}
export type EyeScanContentType = "goldenRatio" | "asymmetry" | "aspectRatio" | "tailAngle";

export type NoseScanContentType =
  | "noseHeight"
  | "noseLength"
  | "foreheadNoseAngle"
  | "nasolabialAngle"
  | "noseTipShape"
  | "noseBridgeWidth"
  | "columellarLobularAngle";

export type FaceContourContentType = "faceShapeType" | "zygomaticRatio" | "mandibularRatio" | "symmetryRatio";

export interface FaceScanResultDescription {
  scanResults: {
    typeName: string;
    metrics: {
      position: string | null;
      value: number;
      unit?: string;
    }[];
  }[];
  description: string;
}

export interface FaceStyle {
  id: number;
  name: string;
  title: string;
  photoUrl: string;
  landmarks: {
    alignedUrl: string | null;
    jsonUrl: string | null;
  };
  parts: FaceStylePart[];
}

export interface FaceStylePart {
  id: number;
  type: string;
  photoUrl: string;
  descriptions: string[];
  references: string[];
  estimation: {
    total: number;
  };
}

export interface OperationCategoryGroup {
  id: number;
  name: string;
  ordinal: number;
  subjectCode: Entity.Type.SUBJECT_CODE;
  categories: OperationCategory[];
}

export interface Note {
  id: number;
  hospitalId: number;
  createdAt: Date;
  afoterUserId: number | null;
  afoterUserNickname: string | null;
  customerName: string | null;
  customerPhoneNumber: string | null;
  customerGender: Entity.Type.GENDER | null;
  referral: string | null;
  memo: string | null;
  reservationDate: Date | null;
  consultantName: string | null;
  consultantUserKey: number | null;
  consultingStatus: Entity.Type.ConsultStatusForFaceFit | null;
  reservationStatus: Entity.Type.ReservationStatus | null;
  photos: ConsultingPhoto[];
  operationCategories: {
    id: number;
    operationCategoryId?: number;
    operationName: string;
    subjectCode: Entity.Type.SUBJECT_CODE;
    ordinal: number;
  }[];
  records: ConsultingRecording[];
}

export interface ConsultingPhoto {
  id: number;
  type: Entity.Type.CONSULTING_PHOTO_TYPE;
  ordinal: number | null;
  originUrl: string | null;
  resizedUrl: string | null;
}

export interface ConsultingRecording {
  id: number;
  createdAt: string;
  recording: RecordingFileInfo;
  transcription: {
    naverObjectStorageUrl: string;
    resultCallbackToken: string;
    token: string;
    status: string;
    result: {
      result: string;
      message: string;
      segments: ConsultingTranscriptionSegment[] | null;
    };
  };
  transcriptionKeywords: TranscriptionKeywords | null;
  transcriptionSummary: { items: TranscriptionSummary[] } | null;
}

export interface TranscriptionKeywords {
  client: string[];
  consultant: string[];
}

export interface TranscriptionSummary {
  startTime: number;
  endTime: number;
  subject: string;
  contents: string[];
}

export interface RecordingFileInfo {
  filename: string;
  googleCloudStorageUrl: string;
  naverObjectStorageUrl: string;
}

export interface ConsultingTranscriptionSegment {
  start: number;
  end: number;
  text: string;
  confidence: number;
  speaker: {
    label: string;
    name: string;
    edited: boolean;
  };
  words: [number, number, string];
  textEdited: string;
}

export interface Consultation {
  id: number;
  hospitalId: number;
  createdAt: Date;
  afoterUserId: number | null;
  afoterUserNickname: string | null;
  customerName: string | null;
  customerPhoneNumber: string | null;
  customerGender: Entity.Type.GENDER | null;
  referral: string | null;
  memo: string | null;
  reservationDate: Date | null;
  consultantName: string | null;
  consultantUserKey: number | null;
  consultingStatus: Entity.Type.ConsultStatusForFaceFit | null;
  reservationStatus: Entity.Type.ReservationStatus | null;
  photos: ConsultingPhoto[];
  noteNumber: string;
  operationCategories: {
    id: number;
    operationName: string;
    subjectCode: Entity.Type.SUBJECT_CODE;
  }[];
  logs: {
    id: number;
    code: Entity.Type.LOG_CODE;
    createdAt: Date;
  }[];
  recordings: {
    id: number;
  }[];
}

export interface CategoryKeyword {
  category: string;
  keywords: Keyword[];
}
export interface Keyword {
  keyword: string;
  subKeywords: string[];
  searchedKeywordResult: SearchedKeywordResult;
}

export interface SearchedKeywordResult {
  segments: SearchedSegmentInfo[];
  totalSearchCount: number;
  currentIndex: number;
}

export interface SearchedSegmentInfo {
  segmentIndex: number;
  keywordPosition: number;
  matchedKeyword: string;
}

export interface FaceScanResult {
  id: number;
  notePhotoId: number;
  alignedImageURL: string;
  faceLandmarksJSONURL: string;
}

export type BestPracticeEditMainSubject = "EYES" | "NOSE" | "FACIAL_CONTOURING";

export interface BestPracticeSearchParam {
  keyword?: string;
  tagIds?: number[];
  categoryIds?: number[];
  gender?: Entity.Type.GENDER[];
}
