export const VIEW_MODEL_SYMBOLS = {
  AdvertiseConsultingCustomersViewModel: Symbol.for("AdvertiseConsultingCustomersViewModel"),
  FaceSimilarityFittingViewModel: Symbol.for("FaceSimilarityFittingViewModel"),
  FaceBestPracticesSliderViewModel: Symbol.for("FaceBestPracticesSliderViewModel"),
  FaceShiftViewModel: Symbol.for("FaceShiftViewModel"),
  FACE_SCAN_FRONTAL_CANVAS_VIEW_MODEL: Symbol.for("FACE_SCAN_FRONTAL_CANVAS_VIEW_MODEL"),
  FACE_FIT_CUSTOMER_REGISTRATION_VIEW_MODEL: Symbol.for("FaceFitCustomerRegistrationViewModel"),
  ACCOUNTS_VIEW_MODEL: Symbol.for("AccountsViewModel"),
  ADVERTISE_CONSULTING_CUSTOMERS_VIEW_MODEL: Symbol.for("ADVERTISE_CONSULTING_CUSTOMERS"),
  BEST_PRACTICE_VIEW_MODEL: Symbol.for("BEST_PRACTICE_VIEW_MODEL"),
  CONSULTATION_RECORDING_VIEW_MODEL: Symbol.for("CONSULTATION_RECORDING_VIEW_MODEL"),
  FACE_SCAN_LEFT_45_VIEW_MODEL: Symbol.for("FaceScanLeft45ViewModel"),
  FACE_SCAN_RIGHT_45_VIEW_MODEL: Symbol.for("FaceScanRight45ViewModel"),
  CONTACT_MANAGER_VIEW_MODEL: Symbol.for("ContactManagerViewModel"),
  DOCTOR_DETAILS_VIEW_MODEL: Symbol.for("DoctorDetailsViewModel"),
  DOCTORS_V2_VIEW_MODEL: Symbol.for("DoctorsV2ViewModel"),
  FACE_BEST_PRATICES_SLIDER_VIEW_MODEL: Symbol.for("FaceBestPracticesSliderViewModel"),
  FACE_FIT_NOTE_DETAILS_VIEW_MODEL: Symbol.for("FaceFitNoteDetailsViewModel"),
  FACE_SHIFT_VIEW_MODEL: Symbol.for("FaceShiftViewModel"),
  FACE_SIMILARITY_FITTING_VIEW_MODEL: Symbol.for("FaceSimilarityFittingViewModel"),
  FACE_STYLE_VIEW_MODEL: Symbol.for("FaceStyleViewModel"),
  HOSPITAL_INFORMATION_VIEW_MODEL: Symbol.for("HospitalInformationViewModel"),
  IMAGE_VIEW_MODEL: Symbol.for("ImageViewModel"),
  PLAN_SETTING_VIEW_MODEL: Symbol.for("PlanSettingViewModel"),
  RECENT_CONSULTATIONS_VIEW_MODEL: Symbol.for("RecentConsultationsViewModel"),
  FACE_SCAN_CAVASES_VIEW_MODEL: Symbol.for("FaceScanCanvasesViewModel"),
  CONSULTANT_ANALYTICS_VIEW_MODEL: Symbol.for("ConsultantAnalyticsViewModel"),
  PERFORMANCE_ANALYTICS_VIEW_MODEL: Symbol.for("PerformanceAnalyticsViewModel"),
  HOSPITAL_ANALYTICS_VIEW_MODEL: Symbol.for("HospitalAnalyticsViewModel"),
  BEST_PRACTICE_EDIT_VIEW_MODEL: Symbol.for("BestPracticeEditViewModel"),
  BEST_PRACTICE_ADD_VIEW_MODEL: Symbol.for("BestPracticeAddViewModel")
};
