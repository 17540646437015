import type { UISystemManager } from "@/application/ui-system/view-model/model";
import * as Entity from "@/domain/entity";
import type { GetBestPractices } from "@/domain/usecase/consulting/model/GetBestPractices";
import type { GetHospitalFaceFitConfiguration } from "@/domain/usecase/consulting/model/GetHospitalFaceFitConfiguration";
import type { UpdateBestPracticeExposureStatus } from "@/domain/usecase/consulting/model/UpdateBestPracticeExposureStatus";
import type { UpdateHospitalFaceFitConfiguration } from "@/domain/usecase/consulting/model/UpdateHospitalFaceFitConfiguration";
import type { GetOperationCategories } from "@/domain/usecase/hospital/model/GetOperationCategories";
import type { GetTags } from "@/domain/usecase/consulting/model/GetTags";
import { FaceFit } from "@view-data/index";
import { BestPracticeViewModel } from "@view-model/model/BestPracticeViewModel";
import { injectable } from "inversify";
import { mergeMap, Subject, zip } from "rxjs";
@injectable()
export class BestPracticeViewModelImpl implements BestPracticeViewModel {
  data: BestPracticeViewModel["data"] = {
    page: 1,
    size: 20,
    total: null,
    publicDataBlurred: false,
    isNextPageLoading: false,
    photoInfos: [],
    operationCategories: [],
    categoryIds: null,
    selectedSubject: "EYES",
    searchParam: {},
  };

  output: BestPracticeViewModel["output"] = {
    page: new Subject<number>(),
    size: new Subject<number>(),
    keyword: new Subject<string>(),
    publicDataBlurred: new Subject<boolean>(),
    total: new Subject<number | null>(),
    photoInfos: new Subject<FaceFit.BestPracticeConfig[]>(),
    selectedSubject: new Subject<Entity.Type.SUBJECT_CODE>(),
    subjectFilterOptions: new Subject<Entity.Type.SUBJECT_CODE[]>(),
    searchOptionCategories: new Subject<FaceFit.OperationCategory[]>(),
    searchOptionTags: new Subject<FaceFit.Tag[]>(),
  };

  route: BestPracticeViewModel["route"] = {
    toQuery: new Subject<{ page: number; size: number; subject: string; searchParam: FaceFit.BestPracticeSearchParam }>(),
    toAddBestPractice: new Subject<void>(),
    toEditBestPractice: new Subject<{ bestPracticeId: number }>(),
  };

  constructor(
    page: number,
    size: number,
    subject: string,
    readonly uiSystem: UISystemManager,
    private readonly ucGetBestPractices: GetBestPractices,
    private readonly updateBestPracticeExposureStatus: UpdateBestPracticeExposureStatus,
    private readonly ucGetOperationCategories: GetOperationCategories,
    private readonly ucGetHospitalFaceFitConfiguration: GetHospitalFaceFitConfiguration,
    private readonly ucUpdateHospitalFaceFitConfiguration: UpdateHospitalFaceFitConfiguration,
    private readonly ucGetTags: GetTags,
    keyword?: string,
    gender?: string,
    categoryIds?: string,
    tagIds?: string,
  ) {
    this.data.page = page;
    this.data.size = size;
    try {
      this.data.searchParam = {
        keyword,
        gender: gender ? (gender.split(",") as Entity.Type.GENDER[]) : undefined,
        categoryIds: categoryIds ? categoryIds.split(",").map((item) => Number(item)) : undefined,
        tagIds: tagIds ? tagIds.split(",").map((item) => Number(item)) : undefined,
      };
      console.log(this.data.searchParam);
    } catch (e) {
      console.log(e);
    }
    this.data.selectedSubject = subject as Entity.Type.SUBJECT_CODE;
    this.init();
  }

  input: BestPracticeViewModel["input"] = {
    clickPage: (page) => {
      this.data.page = page;
      this.getBestPractices();
    },
    clickPublicDataBlurOnOff: () => {
      this.uiSystem.loadingHandler.backdropLoading.next(true);
      this.ucUpdateHospitalFaceFitConfiguration
        .execute({
          publicDataBlurred: !this.data.publicDataBlurred,
        })
        .subscribe({
          next: () => {
            this.uiSystem.toastHandler.toast.next({
              message: "설정되었습니다.",
              position: { vertical: "top", horizontal: "center" },
              type: "SUCCESS",
            });
            (this.data.publicDataBlurred = !this.data.publicDataBlurred), this.output.publicDataBlurred.next(this.data.publicDataBlurred);
            this.uiSystem.loadingHandler.backdropLoading.next(false);
          },
          error: () => {
            this.uiSystem.loadingHandler.backdropLoading.next(false);
          },
        });
    },
    clickPhotoExposureOnOff: (exposure, bestId, ids) => {
      let exposureOff: number[] = [];
      let exposureOn: number[] = [];

      if (exposure === "ON") {
        exposureOn = exposureOn.concat(ids);
      } else {
        exposureOff = exposureOff.concat(ids);
      }

      this.data.photoInfos = this.data.photoInfos.map((best) => {
        if (best.id === bestId) {
          best.photos = best.photos.map((photo) => {
            photo.exposure = !photo.exposure;
            return photo;
          });
          return best;
        } else {
          return best;
        }
      });
      this.output.photoInfos.next(this.data.photoInfos);

      const sub = this.updateBestPracticeExposureStatus.execute({ exposureOff, exposureOn }).subscribe({
        next: () => {
          this.init();
          this.uiSystem.toastHandler.toast.next({
            message: "노출상태가 변경되었습니다.",
            position: { vertical: "top", horizontal: "center" },
            type: "SUCCESS",
          });
          sub.unsubscribe();
        },
        error: () => {
          this.init();
          this.uiSystem.errorHandler.alert.next({ message: "정상적으로 실행하지 못하였습니다.\n잠시후 다시 시도해주세요." });
          sub.unsubscribe();
        },
      });
    },
    clickSubjectFilter: (subjectCode) => {
      this.data.selectedSubject = subjectCode;
      this.data.searchParam = {};
      this.output.selectedSubject.next(this.data.selectedSubject);
      this.event.onUpdatePage(1, 20, subjectCode, this.data.searchParam);
    },
    clickBestPracticeItem: (item) => {
      this.route.toEditBestPractice.next({
        bestPracticeId: item.id,
      });
    },
    clickSearch: (searchParam) => {
      this.data.searchParam = searchParam;
      this.data.page = 1;
      this.getBestPractices();
    },
  };

  event: BestPracticeViewModel["event"] = {
    onUpdatePage: (page, size, subjectCode, searchParam) => {
      this.data.page = page;
      this.data.size = size;
      this.data.searchParam = searchParam;
      this.data.categoryIds = this.getSelectedSubjectCategoryIds(subjectCode, this.data.operationCategories);
      this.getBestPractices();
    },
    onSuccessEmbed: () => {
      this.getBestPractices();
    },
  };

  private init() {
    const sub = this.ucGetHospitalFaceFitConfiguration
      .execute()
      .pipe(
        mergeMap((output) => {
          this.data.publicDataBlurred = output.publicDataBlurred;
          this.output.publicDataBlurred.next(this.data.publicDataBlurred);
          return zip(this.ucGetOperationCategories.execute(), this.ucGetTags.execute());
        }),
      )
      .subscribe({
        next: ([categories, { tags }]) => {
          const subjectFilterOptions = this.getSubjectFilterOptions(categories.items);
          this.data.operationCategories = categories.items;
          this.output.subjectFilterOptions.next(subjectFilterOptions);
          this.output.searchOptionCategories.next(this.data.operationCategories);
          this.output.searchOptionTags.next(tags);
          let ids: number[] = [];
          if (subjectFilterOptions.includes(this.data.selectedSubject)) {
            ids = this.getSelectedSubjectCategoryIds(this.data.selectedSubject, this.data.operationCategories);
            this.output.selectedSubject.next(this.data.selectedSubject);
          } else {
            ids = this.getSelectedSubjectCategoryIds(subjectFilterOptions[0], this.data.operationCategories);
          }
          this.data.categoryIds = ids;
          this.getBestPractices();
          sub.unsubscribe();
        },
        error: () => {
          this.uiSystem.errorHandler.alert.next({
            message: "정상적으로 실행하지 못하였습니다.\n잠시후 다시 시도해주세요.",
          });
          sub.unsubscribe();
        },
      });
  }

  private getBestPractices = () => {
    this.uiSystem.loadingHandler.backdropLoading.next(true);
    const sub = this.ucGetBestPractices
      .execute({
        page: this.data.page,
        size: this.data.size,
        gender: this.data.searchParam.gender ?? null,
        tags: this.data.searchParam.tagIds ?? null,
        categories: this.data.searchParam.categoryIds ? this.data.searchParam.categoryIds : this.data.categoryIds,
        keyword: this.data.searchParam.keyword ?? null,
        exposure: null,
      })
      .subscribe({
        next: ({ items, total }) => {
          this.data.total = total;

          const result: FaceFit.BestPracticeConfig[] = items.map((item) => {
            return {
              id: item.id,
              hospitalScopedId: item.hospitalScopedId,
              publicData: item.publicData,
              categories: item.categories.map((c) => c.operationName),
              customerLabel: item.customerLabel,
              elapsedTime: item.elapsedTime,
              gender: item.gender,
              photos: item.photos.map((photo) => {
                return {
                  id: photo.id,
                  ordinal: photo.ordinal,
                  photo: photo.originUrl,
                  exposure: photo.exposure,
                  type: photo.type,
                  kind: photo.kind,
                  lastEmbeddedAt: photo.lastEmbeddedAt,
                };
              }),
              tags: item.tags,
            };
          });

          const sortedPhotos = this.sortPhotos(result);
          this.data.photoInfos = sortedPhotos;

          this.output.photoInfos.next(this.data.photoInfos);
          this.output.total.next(total);
          this.route.toQuery.next({
            page: this.data.page,
            size: this.data.size,
            subject: this.data.selectedSubject,
            searchParam: this.data.searchParam,
          });
          this.uiSystem.loadingHandler.backdropLoading.next(false);
          sub.unsubscribe();
        },
        error: () => {
          this.uiSystem.errorHandler.alert.next({
            message: "정상적으로 실행하지 못하였습니다.\n잠시후 다시 시도해주세요.",
          });
          this.uiSystem.loadingHandler.backdropLoading.next(false);
          sub.unsubscribe();
        },
      });
  };

  private sortPhotos = (photoInfos: FaceFit.BestPracticeConfig[]): FaceFit.BestPracticeConfig[] => {
    // photoInfos.sort((a, b) => {
    //   if (a.id > b.id) {
    //     return 1;
    //   } else {
    //     return -1;
    //   }
    // });

    photoInfos = photoInfos.map((photoInfo) => {
      photoInfo.photos = photoInfo.photos.sort((a, b) => {
        if (a.ordinal > b.ordinal) {
          return 1;
        } else if (a.ordinal < b.ordinal) {
          return -1;
        } else if (a.ordinal === b.ordinal) {
          if (a.type === "BEFORE") {
            return -1;
          } else {
            return 1;
          }
        } else {
          return 0;
        }
      });
      return photoInfo;
    });

    return photoInfos;
  };

  private getSelectedSubjectCategoryIds = (subject: Entity.Type.SUBJECT_CODE, categories: FaceFit.OperationCategory[]) => {
    return categories.filter((category) => category.subjectCode === subject).map((category) => category.id);
  };

  private getSubjectFilterOptions = (categories: FaceFit.OperationCategory[]): Entity.Type.SUBJECT_CODE[] => {
    const customOrder: Entity.Type.SUBJECT_CODE[] = ["EYES", "NOSE", "FACIAL_CONTOURING"];
    const subjectCodes = Array.from(new Set(categories.map((category) => category.subjectCode)));
    return subjectCodes.sort((a, b) => customOrder.indexOf(a) - customOrder.indexOf(b));
  };
}
